export interface IPagination {
  page?: string;
  pageSize?: string;
}

export interface IQueryParams extends IPagination {
  [key: string]: string | undefined;
}

export const defaultList = {
  rows: [],
  count: 0,
};

export interface ISubtaskServerObjectResponse<T> {
  statusCode: number;
  row: T;
}

export interface ISubtaskServerListResponse<T> {
  statusCode: number;
  rows: T[];
  count: number;
}

export interface ISubtaskServerHttpError {
  status: number; // 404
  message: string; // "Request failed with status code 404"
  response?: {
    status?: number; // 404
    data?: {
      statusCode?: number; // 404
      errorCode?: string; // "PROJECT_EMPTY_LIST"
      message?: string; // "조건에 해당하는 프로젝트가 존재하지 않습니다."
      error?: string; // "NotFoundException"
    };
  };
}

export interface ISimpleMedia {
  id: number;
  mediaUrl: string;
  originalFileName: string;
  sSizeImageUrl?: string;
  mSizeImageUrl?: string;
  lSizeImageUrl?: string;
}

export enum MediaFolder {
  TESTS = "tests/",
  COMMONS = "commons/",
  PROJECT_INTRODUCTIONS = "project-introductions/",
}

export interface IMedia {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  service: string;
  mediaUrl: string;
  sSizeImageUrl?: string;
  mSizeImageUrl?: string;
  lSizeImageUrl?: string;
  mediaType: string;
  fileExtension: string;
  originalFileName: string;
  folder: MediaFolder;
}
