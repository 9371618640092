import { useNavigate, useParams } from "react-router-dom";
import { useGetRecruitingProjectDetail } from "../../../hooks/queries/useProjectQuery";
import styled from "styled-components";
import {
  defaultProjectDetail,
  meetingChannelViewer,
  projectPlatformListViewer,
} from "../../../types/project.type";
import { isoStringToDateTypeA } from "../../../helpers/date.helper";
import { jobViewer } from "../../../types";
import { Button } from "antd";
import MyApplicationInfo from "./MyApplicationInfo";
import ApplicantsInfo from "./ApplicantsInfo";
import { pageRoute } from "../../../constants/pageRoute.constant";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 754px;
  padding-bottom: 50px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.backgroundColor.white};
`;

const Title = styled.h1`
  width: 100%;
  padding: 34px 32px;
  font-size: ${(props) => props.theme.textSize.large};
`;

const ModifyButton = styled(Button)`
  position: absolute;
  top: 26px;
  right: 30px;
  height: 52px;
  width: 58px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 0px 58px;
  font-size: ${(props) => props.theme.textSize.small};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 48px;
`;

const ItemField = styled.div``;

const ItemValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
  white-space: pre-line;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: ${(props) => props.theme.textColor.gray};
`;

const ItemListItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const ScrollableText = styled.div`
  overflow-x: scroll; /* 가로 스크롤 활성화 */
  -webkit-overflow-scrolling: touch; /* 부드러운 스크롤 (모바일 지원) */

  /* 스크롤바 숨기기 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

function ProjectDetail() {
  const { id } = useParams<{ id: string }>();
  const projectId = id!;
  const { data: projectDetail = defaultProjectDetail, isFetching } =
    useGetRecruitingProjectDetail({
      projectId,
    });
  const navigate = useNavigate();

  const {
    projectName,
    projectPlatformList,
    simpleProjectTasks,
    meetingChannel,
    meetingSchedule,
    desiredDeadlineAt,
    description,
    isProjectRegistrant,
    preferredMeetingRegionView,
  } = projectDetail;

  return (
    <Container>
      <Title>프로젝트 정보</Title>

      {isProjectRegistrant && (
        <ModifyButton
          onClick={() => navigate(`${pageRoute.PROJECT_LIST}/${id}/edit`)}
        >
          수정
        </ModifyButton>
      )}

      <ItemsWrapper>
        <Item>
          <ItemField>프로젝트명</ItemField>
          <ItemValue>{projectName}</ItemValue>
        </Item>
        <Item>
          <ItemField>플랫폼</ItemField>
          <ItemValue>
            {projectPlatformListViewer(projectPlatformList)}
          </ItemValue>
        </Item>
        <Item>
          <ItemField>직무</ItemField>
          <ItemList>
            {simpleProjectTasks.map((simpleProjectTask, idx) => {
              const {
                job,
                isProjectRegistrantTask,
                recruitsNumber,
                jobDescription,
              } = simpleProjectTask;

              return (
                <ItemListItem key={idx}>
                  <div style={{ flex: 3 }}>
                    {jobViewer(job) +
                      (isProjectRegistrantTask ? "(프로젝트 등록자)" : "")}
                  </div>
                  <div style={{ flex: 1 }}>{recruitsNumber}명</div>
                  <ScrollableText style={{ flex: 5 }}>
                    {jobDescription}
                  </ScrollableText>
                </ItemListItem>
              );
            })}
          </ItemList>
        </Item>
        <Item>
          <ItemField>회의 장소</ItemField>
          <ItemValue>{meetingChannelViewer(meetingChannel)}</ItemValue>
        </Item>
        {preferredMeetingRegionView && (
          <Item>
            <ItemField>회의 선호 지역</ItemField>
            <ItemValue>{preferredMeetingRegionView}</ItemValue>
          </Item>
        )}
        <Item>
          <ItemField>회의 일정</ItemField>
          <ItemValue>{meetingSchedule}</ItemValue>
        </Item>
        <Item>
          <ItemField>목표 마감 기한</ItemField>
          <ItemValue>{isoStringToDateTypeA(desiredDeadlineAt)}</ItemValue>
        </Item>
        <Item>
          <ItemField>모집 내용</ItemField>
          <ItemValue>{description}</ItemValue>
        </Item>
      </ItemsWrapper>

      {!isFetching && isProjectRegistrant && (
        <ApplicantsInfo projectId={projectId} />
      )}

      {!isFetching && !isProjectRegistrant && (
        <MyApplicationInfo projectId={projectId} />
      )}
    </Container>
  );
}

export default ProjectDetail;
