import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/layouts/Header/Header";
import AppRoutes from "./routes/AppRoutes";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { localStorageKey } from "./constants/localStorageKey.constant";
import { userState } from "./atoms/accountState";
import { getLocalStorage } from "./helpers/storage.helper";
import Loading from "./components/shared/Loading";
import Modals from "./components/shared/Modals";
import Sidebar from "./components/layouts/Sidebar/Sidebar";
import ScrollToTop from "./components/shared/ScrollToTop";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  @media (max-width: 1246px) {
    align-items: flex-start;
    margin-left: 246px;
  }
  margin-top: 120px;
  margin-bottom: 50px;
`;

function App() {
  // TODO isAuthenticated에 대한 고민 필요
  const [user, setUser] = useRecoilState(userState);

  const isAuthenticated = getLocalStorage(localStorageKey.BEARER_TOKEN);
  if (!isAuthenticated && user) {
    setUser(null);

    return null;
  }

  return (
    <Router>
      <Loading />
      <Modals />
      <ScrollToTop />
      <Header />
      <Sidebar />
      <Main>
        <AppRoutes />
      </Main>
    </Router>
  );
}

export default App;
