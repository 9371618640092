export const scrollToTop = function (): void {
  window.scrollTo(0, 0);
};

export const getAbsoluteUrl = function (url?: string): string | undefined {
  if (!url) return undefined;

  if (!url.startsWith("http://") && !url.startsWith("https://"))
    return `https://${url}`;

  return url;
};
