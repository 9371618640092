import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { pageRoute } from "../../../constants/pageRoute.constant";
import { useSignOut } from "../../../hooks/queries/useAccountQuery";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { isGlobalLoadingState } from "../../../atoms/loadingState";

const StyledSidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: fixed;
  top: 70px;
  left: 0;
  width: 246px;
  z-index: 1000;
  padding: 60px 40px;
`;

const MenuTitle = styled.div`
  color: ${({ theme }) => theme.textColor.lighterGray};
`;

const MenuLink = styled(Link)<{ $isActive: boolean }>`
  padding: 0px 20px;
  font-weight: ${({ $isActive, theme }) =>
    $isActive ? theme.fontWeight.medium : theme.fontWeight.regular};
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.textColor.black : theme.textColor.gray};
  &:hover {
    text-decoration: underline;
  }
`;

const MenuButton = styled.div`
  padding: 0px 20px;
  color: ${({ theme }) => theme.textColor.gray};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

function SidebarMypage() {
  const location = useLocation();
  const setIsGlobalLoading = useSetRecoilState(isGlobalLoadingState);
  const { mutate } = useSignOut();

  useEffect(() => {
    return () => {
      setIsGlobalLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  const handleSignOut = () => {
    mutate();

    setIsGlobalLoading(true);
  };

  return (
    <StyledSidebar>
      <MenuTitle>마이페이지</MenuTitle>
      <MenuLink
        to={pageRoute.MYPAGE}
        $isActive={location.pathname === pageRoute.MYPAGE}
      >
        내 정보
      </MenuLink>
      <MenuButton onClick={handleSignOut}>로그아웃</MenuButton>
    </StyledSidebar>
  );
}

export default SidebarMypage;
