import { Link, matchPath, useLocation } from "react-router-dom";
import styled from "styled-components";
import { pageRoute } from "../../../constants/pageRoute.constant";

const StyledSidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: fixed;
  top: 70px;
  left: 0;
  width: 246px;
  z-index: 1000;
  padding: 60px 40px;
`;

const MenuTitle = styled.div`
  color: ${({ theme }) => theme.textColor.lighterGray};
`;

const MenuLink = styled(Link)<{ $isActive: boolean }>`
  padding: 0px 20px;
  font-weight: ${({ $isActive, theme }) =>
    $isActive ? theme.fontWeight.medium : theme.fontWeight.regular};
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.textColor.black : theme.textColor.gray};
  &:hover {
    text-decoration: underline;
  }
`;

function SidebarMyProjects() {
  const location = useLocation();

  const pathname = location.pathname;
  const isMyRecruitingProject = !!matchPath(
    pageRoute.MY_PROJECT_RECRUITING_PROJECT_LIST + "/*",
    pathname
  );
  const isMyAppliedProject = !!matchPath(
    pageRoute.MY_PROJECT_APPLIED_PROJECT_LIST + "/*",
    pathname
  );
  const isMyInProgressProject = !!matchPath(
    pageRoute.MY_PROJECT_IN_PROGRESS_PROJECT_LIST + "/*",
    pathname
  );
  const isMyCompleteProject = !!matchPath(
    pageRoute.MY_PROJECT_COMPLETE_PROJECT_LIST + "/*",
    pathname
  );

  return (
    <StyledSidebar>
      <MenuTitle>내 프로젝트 관리</MenuTitle>
      <MenuLink
        to={pageRoute.MY_PROJECT_RECRUITING_PROJECT_LIST}
        $isActive={isMyRecruitingProject}
      >
        프로젝트 등록
      </MenuLink>
      <MenuLink
        to={pageRoute.MY_PROJECT_APPLIED_PROJECT_LIST}
        $isActive={isMyAppliedProject}
      >
        프로젝트 지원
      </MenuLink>
      <MenuLink
        to={pageRoute.MY_PROJECT_IN_PROGRESS_PROJECT_LIST}
        $isActive={isMyInProgressProject}
      >
        프로젝트 진행
      </MenuLink>
      <MenuLink
        to={pageRoute.MY_PROJECT_COMPLETE_PROJECT_LIST}
        $isActive={isMyCompleteProject}
      >
        프로젝트 완료
      </MenuLink>
    </StyledSidebar>
  );
}

export default SidebarMyProjects;
