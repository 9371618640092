const nodeEnvs = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
} as const;
const NODE_ENV_ARRAY = Object.values(nodeEnvs);

export const commonConstants = {
  // 환경변수
  nodeEnvs,
  NODE_ENV_ARRAY,
  PAGINATION_DEFAULT_PAGE: "1",
  PAGINATION_DEFAULT_PAGE_SIZE: "10",
};
