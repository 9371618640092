import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorage } from "../helpers/storage.helper";
import { localStorageKey } from "../constants/localStorageKey.constant";
import { pageRoute } from "../constants/pageRoute.constant";
import { isProfileCompleteSelector } from "../atoms/accountState";
import { useRecoilValue } from "recoil";

function ProfileCompleteRoutes() {
  const isAuthenticated = getLocalStorage(localStorageKey.BEARER_TOKEN);
  const isProfileComplete = useRecoilValue(isProfileCompleteSelector);

  if (!isAuthenticated) {
    return <Navigate to={pageRoute.SIGN_IN} />;
  }

  if (!isProfileComplete) {
    alert("내 정보를 입력해야 확인할 수 있어요.");

    return <Navigate to={pageRoute.MYPAGE} />;
  }

  return <Outlet />;
}

export default ProfileCompleteRoutes;
