import styled from "styled-components";
import {
  IUseGetRecruitingProjectsParams,
  useGetRecruitingProjects,
} from "../../../hooks/queries/useProjectQuery";
import { Pagination, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import useQueryParams from "../../../hooks/useQueryParams";
import { commonConstants } from "../../../constants/common.constant";
import { isoStringToDateTypeA } from "../../../helpers/date.helper";
import {
  jobOptions,
  platformOptions,
} from "../../../constants/selectProps.constant";
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import { pageRoute } from "../../../constants/pageRoute.constant";
import checkIcon from "../../../assets/icons/check.png";
import hiIcon from "../../../assets/icons/hi.png";
import { scrollToTop } from "../../../helpers/common.helper";
import { defaultList } from "../../../types/common.type";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 553px;
  width: 754px;
  padding-top: 34px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.backgroundColor.white};
`;

const Title = styled.h1`
  width: 100%;
  padding: 0px 32px;
  font-size: ${(props) => props.theme.textSize.large};
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin: 37px 0;
`;

const StyledSelect = styled(Select<string>)`
  height: 40px;
  font-size: ${(props) => props.theme.textSize.tiny};
  .ant-select-selection-item {
    font-size: ${(props) => props.theme.textSize.tiny};
  }
`;

const StyledSearch = styled(Search)`
  width: 171px;
  .ant-input,
  .ant-btn {
    height: 40px;
  }
`;

const List = styled.div`
  width: 640px;
  padding-bottom: 10px;
  font-size: ${(props) => props.theme.textSize.small};
`;

const ListTotalCount = styled.div`
  margin-bottom: 20px;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  position: relative;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  &:hover {
    cursor: pointer;
  }
`;

const ListItemLabel = styled.div<{ $backgroundColor?: "softBlue" }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 24px;
  right: 24px;
  height: 30px;
  width: 89px;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor === "softBlue"
      ? theme.backgroundColor.softBlue
      : theme.backgroundColor.lightGreen};
  color: ${({ $backgroundColor, theme }) =>
    $backgroundColor === "softBlue"
      ? theme.textColor.deepBlue
      : theme.textColor.green};
  font-size: ${({ theme }) => theme.textSize.teeny};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const ListItemLabelIcon = styled.img`
  height: 16px;
  width: auto;
  margin-right: 8px;
`;

const ListItemField = styled.div`
  display: flex;
`;

const ListItemFieldLabel = styled.div`
  width: 135px;
  flex-shrink: 0;
`;

const ListItemFieldValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
`;

const ListItemRegisteredAt = styled.div`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${(props) => props.theme.textColor.lighterGray};
`;

function ProjectList() {
  const { queryParams, updateQueryParams } = useQueryParams();
  const page = queryParams.page || commonConstants.PAGINATION_DEFAULT_PAGE;
  const pageSize = commonConstants.PAGINATION_DEFAULT_PAGE_SIZE;
  const [filters, setFilters] = useState<IUseGetRecruitingProjectsParams>();
  const { data: projectList = defaultList, refetch } = useGetRecruitingProjects(
    { enabled: false, params: { page, pageSize, ...queryParams } }
  );
  const { rows: projects, count } = projectList;
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  useEffect(() => {
    const { job, projectPlatform, projectName } = queryParams;
    setFilters({ job, projectPlatform, projectName });
  }, [queryParams]);

  const handleJobChange = useCallback(
    (job: string) => {
      setFilters({ ...filters, job });
    },
    [filters]
  );

  const handlePlatformChange = useCallback(
    (projectPlatform: string) => {
      setFilters({ ...filters, projectPlatform });
    },
    [filters]
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilters({ ...filters, projectName: e.target.value });
    },

    [filters]
  );

  const handleSearch = useCallback(
    (projectName: string) => {
      updateQueryParams({ ...filters, projectName }, true);
    },
    [updateQueryParams, filters]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      updateQueryParams({ page });
      scrollToTop();
    },
    [updateQueryParams]
  );

  const handleListItemClick = useCallback(
    (id: number) => {
      navigate(`${pageRoute.PROJECT_LIST}/${id}`);
    },
    [navigate]
  );

  const getJobValue = () => {
    return jobOptions?.some((option) => option.value === filters?.job)
      ? filters?.job
      : undefined;
  };

  const getPlatformValue = () => {
    return platformOptions?.some(
      (option) => option.value === filters?.projectPlatform
    )
      ? filters?.projectPlatform
      : undefined;
  };

  return (
    <Container>
      <Title>프로젝트 찾기</Title>

      <FilterWrapper>
        <StyledSelect
          allowClear
          optionFilterProp="label"
          placeholder="직무별"
          options={jobOptions}
          onChange={handleJobChange}
          value={getJobValue()}
          style={{ width: 105 }}
        />

        <StyledSelect
          allowClear
          optionFilterProp="label"
          placeholder="플랫폼별"
          options={platformOptions}
          onChange={handlePlatformChange}
          value={getPlatformValue()}
          style={{ width: 119 }}
        />

        <StyledSearch
          placeholder="프로젝트명 검색"
          value={filters?.projectName}
          onChange={handleSearchChange}
          onSearch={handleSearch}
        />
      </FilterWrapper>

      <List>
        <ListTotalCount>전체 {count}건</ListTotalCount>
        {projects.map((project) => (
          <ListItem
            key={project.id}
            onClick={() => handleListItemClick(project.id)}
          >
            {project.isApplied && (
              <ListItemLabel>
                <ListItemLabelIcon src={checkIcon} />
                지원 완료
              </ListItemLabel>
            )}
            {project.isProjectRegistrant && (
              <ListItemLabel $backgroundColor="softBlue">
                <ListItemLabelIcon src={hiIcon} />
                등록 완료
              </ListItemLabel>
            )}
            <ListItemField>
              <ListItemFieldLabel>프로젝트명</ListItemFieldLabel>
              <ListItemFieldValue>{project.projectName}</ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>플랫폼</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.projectPlatformListView}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>모집직무</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.projectTasksJobView}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>회의 장소</ListItemFieldLabel>
              <ListItemFieldValue>
                {project.meetingChannelView}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemField>
              <ListItemFieldLabel>목표 마감 기한</ListItemFieldLabel>
              <ListItemFieldValue>
                {isoStringToDateTypeA(project.desiredDeadlineAt)}
              </ListItemFieldValue>
            </ListItemField>
            <ListItemRegisteredAt>
              등록일자: {isoStringToDateTypeA(project.createdAt)}
            </ListItemRegisteredAt>
          </ListItem>
        ))}
      </List>

      <Pagination
        style={{ paddingBottom: 35, marginTop: "auto" }}
        align="center"
        defaultCurrent={1}
        total={count}
        pageSize={Number(pageSize)}
        current={Number(page)}
        onChange={handlePageChange}
      />
    </Container>
  );
}

export default ProjectList;
