import { useParams } from "react-router-dom";
import {
  useCompleteMyInProgressProject,
  useGetMyInProgressProjectDetail,
  useUpdateMyInProgressProject,
} from "../../../../hooks/queries/useProjectQuery";
import styled from "styled-components";
import {
  dayjsToDateTypeB,
  isoStringToDateTypeA,
  isoStringToDayjs,
} from "../../../../helpers/date.helper";
import {
  defaultMyInProgressProjectDetail,
  ProjectWorkerProgressStatus,
} from "../../../../types";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Progress,
  Select,
  Space,
  Tooltip,
  UploadFile,
} from "antd";
import { jobViewer } from "../../../../types/account.type";
import { projectWorkerProgressStatusOptions } from "../../../../constants/selectProps.constant";
import {
  ISimpleProjectWorker,
  projectWorkerProgressStatusViewer,
} from "../../../../types/project.type";
import questionIcon from "../../../../assets/icons/question.png";
import { useCallback, useEffect, useState } from "react";
import Upload from "../../../atoms/Upload";
import { urlConstants } from "../../../../constants/url.constant";
import { MediaFolder } from "../../../../types/common.type";
import { useRecoilState } from "recoil";
import { isGlobalLoadingState } from "../../../../atoms/loadingState";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 754px;
  padding-bottom: 50px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.backgroundColor.white};
`;

const Title = styled.h1`
  width: 100%;
  padding: 34px 32px;
  font-size: ${(props) => props.theme.textSize.large};
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 0px 58px;
  font-size: ${(props) => props.theme.textSize.small};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 48px;
`;

const ItemField = styled.div``;

const ItemValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
  white-space: pre-line;
`;

const ItemFieldDescription = styled.div`
  color: ${(props) => props.theme.textColor.lightestGray};
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const ProjectWorkerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 46px;
  width: 100%;
  & > div {
    flex: 1;
  }
`;

const ProjectWorkerField = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TooltipIcon = styled.img`
  height: 20px;
  width: auto;
`;

const ProjectWorkerColumn = styled.div`
  color: ${({ theme }) => theme.textColor.gray};
`;

const StyledInput = styled(Input)`
  height: 52px;
`;

const ModalContent = styled.p`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.darkerGray};
`;

function MyInProgressProjectDetail() {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm<{
    projectIntroductionUrl: string;
    simpleProjectWorkers: ISimpleProjectWorker[];
  }>();
  const projectId = id!;
  const { data: myInProgressProjectDetail = defaultMyInProgressProjectDetail } =
    useGetMyInProgressProjectDetail(projectId);
  const [fileList, setFileList] = useState<(UploadFile & { id?: number })[]>(
    []
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] =
    useRecoilState(isGlobalLoadingState);
  const { mutateAsync: mutateAsyncSave, isLoading: isSaving } =
    useUpdateMyInProgressProject();
  const { mutateAsync: mutateAsyncComplete, isLoading: isCompleting } =
    useCompleteMyInProgressProject();

  useEffect(() => {
    return () => {
      setIsGlobalLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((isSaving || isCompleting) && !isGlobalLoading)
      setIsGlobalLoading(true);
    if (!isSaving && !isCompleting && isGlobalLoading)
      setIsGlobalLoading(false);
    // eslint-disable-next-line
  }, [isSaving, isCompleting]);

  useEffect(() => {
    const { introductionSimpleMedias } = myInProgressProjectDetail;
    const fileList: UploadFile[] = introductionSimpleMedias
      ? introductionSimpleMedias.map((el) => {
          const { id, mediaUrl: url, originalFileName: name } = el;
          const uid = String(id);
          const status = "done";

          return { id, uid, name, status, url };
        })
      : [];
    setFileList(fileList);

    form.resetFields();
  }, [myInProgressProjectDetail, form]);

  const handlePressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault();
    },
    []
  );

  const handleSave = useCallback(() => {
    const values = form.getFieldsValue();
    const { projectIntroductionUrl, simpleProjectWorkers } = values;

    const data = {
      projectIntroductionUrlList: projectIntroductionUrl
        ? [projectIntroductionUrl]
        : [],
      modifyProjectWorkers: simpleProjectWorkers.map((el) => {
        const { id, projectWorkerDeadlineAt, projectWorkerProgressStatus } = el;

        return { id, projectWorkerDeadlineAt, projectWorkerProgressStatus };
      }),
      introductionMediaIdList: fileList
        .filter((el) => el.id)
        .map((el) => el.id!),
    };

    return mutateAsyncSave({ projectId, data });
  }, [form, fileList, projectId, mutateAsyncSave]);

  const handleSaveClick = useCallback(async () => {
    try {
      await handleSave();
      setIsConfirmModalOpen(true);
    } catch (error) {
      message.error("저장에 실패하였습니다.");
    }
  }, [handleSave]);

  const handleComplete = useCallback(async () => {
    try {
      setIsCompleteModalOpen(false);
      await handleSave();
      await mutateAsyncComplete({ projectId });
    } catch (error) {
      message.error("프로젝트 완료에 실패하였습니다.");
    }
  }, [handleSave, mutateAsyncComplete, projectId]);

  const {
    projectName,
    desiredDeadlineAt,
    desiredDeadlineDday,
    totalCompletionRate,
    projectIntroductionUrlList,
    projectCompletedAt,
    simpleProjectWorkers,
  } = myInProgressProjectDetail;
  const [projectIntroductionUrl] = projectIntroductionUrlList;

  const initialValues = {
    projectIntroductionUrl,
    simpleProjectWorkers: simpleProjectWorkers.map((simpleProjectWorker) => {
      const { id, projectWorkerProgressStatus, name, job, score } =
        simpleProjectWorker;
      const projectWorkerDeadlineAt = isoStringToDayjs(
        simpleProjectWorker.projectWorkerDeadlineAt
      );

      return {
        id,
        projectWorkerDeadlineAt,
        projectWorkerProgressStatus,
        name,
        job,
        score,
      };
    }),
  };

  return (
    <Container>
      <Title>프로젝트 지원</Title>

      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        variant="outlined"
      >
        <ItemsWrapper>
          <Item>
            <ItemField>프로젝트명</ItemField>
            <ItemValue>{projectName}</ItemValue>
          </Item>
          <Item>
            <ItemField>목표 마감 일자</ItemField>
            <ItemValue>
              {isoStringToDateTypeA(desiredDeadlineAt)}
              {" ("}
              {desiredDeadlineDday}
              {")"}
            </ItemValue>
          </Item>
          <Item style={{ gap: "0px" }}>
            <ItemField>전체 달성율</ItemField>
            <ItemValue>
              <Progress percent={totalCompletionRate * 100} showInfo={false} />
              {totalCompletionRate * 100}% 달성했어요.
            </ItemValue>
          </Item>
          <Item>
            <ItemField>
              담당자별 달성 점수
              <ItemFieldDescription>
                * 마감 기한내에 진행 상태를 '완료'처리해주세요.
              </ItemFieldDescription>
            </ItemField>
            <ProjectWorkerWrapper>
              <ProjectWorkerField>담당 직무</ProjectWorkerField>
              <ProjectWorkerField>이름</ProjectWorkerField>
              <ProjectWorkerField>마감 기한</ProjectWorkerField>
              <ProjectWorkerField>진행 상태</ProjectWorkerField>
              <ProjectWorkerField>
                달성 점수
                <Tooltip
                  placement="bottomLeft"
                  title="마감 기한까지 진행 상태를 '완료' 처리하면 달성 점수가 오릅니다!"
                  arrow={{ pointAtCenter: true }}
                >
                  <TooltipIcon src={questionIcon} />
                </Tooltip>
              </ProjectWorkerField>
            </ProjectWorkerWrapper>
            <Form.List name="simpleProjectWorkers">
              {(fields) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <ProjectWorkerWrapper key={key}>
                      <ProjectWorkerColumn>
                        {jobViewer(
                          initialValues.simpleProjectWorkers[name].job
                        )}
                      </ProjectWorkerColumn>
                      <ProjectWorkerColumn>
                        {initialValues.simpleProjectWorkers[name].name}
                      </ProjectWorkerColumn>
                      <ProjectWorkerColumn>
                        {initialValues.simpleProjectWorkers[name]
                          .projectWorkerProgressStatus ===
                        ProjectWorkerProgressStatus.COMPLETE ? (
                          dayjsToDateTypeB(
                            initialValues.simpleProjectWorkers[name]
                              .projectWorkerDeadlineAt
                          )
                        ) : (
                          <Form.Item
                            {...restField}
                            name={[name, "projectWorkerDeadlineAt"]}
                            noStyle
                          >
                            <DatePicker
                              allowClear={false}
                              placeholder="선택"
                              format="MM.DD"
                              style={{ width: 100 }}
                            />
                          </Form.Item>
                        )}
                      </ProjectWorkerColumn>
                      <ProjectWorkerColumn>
                        <Form.Item shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            const isComplete =
                              initialValues.simpleProjectWorkers[name]
                                .projectWorkerProgressStatus ===
                              ProjectWorkerProgressStatus.COMPLETE;
                            const projectWorkerDeadlineAt = getFieldValue([
                              "simpleProjectWorkers",
                              name,
                              "projectWorkerDeadlineAt",
                            ]);

                            return isComplete || !projectWorkerDeadlineAt ? (
                              projectWorkerProgressStatusViewer(
                                initialValues.simpleProjectWorkers[name]
                                  .projectWorkerProgressStatus
                              )
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "projectWorkerProgressStatus"]}
                                noStyle
                              >
                                <Select
                                  optionFilterProp="label"
                                  placeholder="직무별"
                                  options={projectWorkerProgressStatusOptions}
                                  style={{ width: 100 }}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </ProjectWorkerColumn>
                      <ProjectWorkerColumn>
                        {initialValues.simpleProjectWorkers[name].score}
                      </ProjectWorkerColumn>
                    </ProjectWorkerWrapper>
                  ))}
                </>
              )}
            </Form.List>
          </Item>
          <Item>
            <ItemField>
              프로젝트 알리기
              <ItemFieldDescription>
                * 어떤 프로젝트를 진행했는지 문서 또는 URL을 통해 보여주세요.
              </ItemFieldDescription>
            </ItemField>
            <Upload
              action={urlConstants.SUBTASK_SERVER_FILE_UPLOAD_URL}
              fileList={fileList}
              setFileList={setFileList}
              folder={MediaFolder.PROJECT_INTRODUCTIONS}
              buttonMessage="문서 첨부"
            />
            <Form.Item name={["projectIntroductionUrl"]} noStyle>
              <StyledInput
                placeholder="URL을 올려주세요."
                autoComplete="off"
                onPressEnter={handlePressEnter}
              />
            </Form.Item>
          </Item>
          <Item>
            <ItemField>프로젝트 완료 일자</ItemField>
            <ItemValue>{isoStringToDateTypeA(projectCompletedAt)}</ItemValue>
          </Item>
        </ItemsWrapper>

        <Form.Item>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              paddingTop: "30px",
            }}
            size="middle"
          >
            {/* // TODO
            <Button
              color="primary"
              variant="outlined"
              htmlType="button"
              onClick={handleFormReset}
              style={{ height: "44px", width: "110px" }}
            >
              취소
            </Button> */}
            <Button
              color="primary"
              variant="solid"
              htmlType="button"
              onClick={handleSaveClick}
              style={{ height: "44px", width: "110px" }}
            >
              저장
            </Button>
            <Button
              color="primary"
              variant="solid"
              htmlType="button"
              onClick={() => setIsCompleteModalOpen(true)}
              style={{
                backgroundColor: "#00AA44",
                height: "44px",
                width: "110px",
              }}
            >
              프로젝트 완료
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <Modal
        open={isConfirmModalOpen}
        footer={[
          <Button
            key="confirm"
            type="primary"
            onClick={() => setIsConfirmModalOpen(false)}
          >
            확인
          </Button>,
        ]}
        onCancel={() => setIsConfirmModalOpen(false)}
        closable={false}
        width={400}
      >
        <ModalContent>수정된 정보가 저장되었어요.</ModalContent>
      </Modal>
      <Modal
        open={isCompleteModalOpen}
        onOk={handleComplete}
        okText="네"
        onCancel={() => setIsCompleteModalOpen(false)}
        cancelText="취소"
        width={400}
      >
        <ModalContent>프로젝트를 완료 처리하시겠어요?</ModalContent>
      </Modal>
    </Container>
  );
}

export default MyInProgressProjectDetail;
