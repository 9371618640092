import { Upload as AntdUpload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadFile, RcFile } from "antd/es/upload/interface";
import { IMedia, MediaFolder } from "../../types/common.type";
import { Dispatch, SetStateAction } from "react";
import { getLocalStorage } from "../../helpers/storage.helper";
import { localStorageKey } from "../../constants/localStorageKey.constant";

interface UploadProps {
  action: string;
  fileList: UploadFile[];
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  folder: MediaFolder;
  maxCount?: number;
  multiple?: boolean;
  buttonMessage?: string;
}

/**
 * SubTask Server를 통해 image/file 업로드 요청
 */
function Upload({
  action,
  fileList,
  setFileList,
  folder,
  maxCount = 1,
  multiple = false,
  buttonMessage = "Click to Upload",
}: UploadProps) {
  const handleUploadChange = (info: {
    file: UploadFile<RcFile>;
    fileList: UploadFile[];
  }) => {
    setFileList(info.fileList);

    if (info.file.status === "done") {
      const [media] = (info.file.response as any).rows as IMedia[];
      const id = media.id;
      const url = media.mediaUrl;
      setFileList((prev) =>
        prev.map((file) =>
          file.uid === info.file.uid ? { ...file, id, url } : file
        )
      );
      // message.success(`${info.file.name} 업로드에 성공하였습니다.`);
    } else if (info.file.status === "error") {
      // setFileList((prev) => prev.filter((file) => file.uid !== info.file.uid));
      message.error(`${info.file.name} 업로드에 실패하였습니다.`);
    }
  };

  return (
    <AntdUpload
      action={action}
      fileList={fileList}
      name="files"
      headers={{
        authorization: getLocalStorage(localStorageKey.BEARER_TOKEN) || "",
      }}
      data={{ folder }}
      maxCount={maxCount}
      multiple={multiple} // TODO multiple={true} 여도 서버 요청은 나눠서 보냄
      onChange={handleUploadChange}
    >
      {fileList.length < maxCount && (
        <Button icon={<UploadOutlined />}>{buttonMessage}</Button>
      )}
    </AntdUpload>
  );
}

export default Upload;
