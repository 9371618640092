export const queryKey = {
  GET_USER: "getUser",
  GET_RECRUITING_PROJECTS: "getRecruitingProjects",
  GET_RECRUITING_PROJECT_DETAIL: "getRecruitingProjectDetail",
  GET_MY_APPLICATION_INFO_BY_PROJECT_ID: "getMyApplicationInfoByProjectId",
  GET_APPLICANTS_BY_PROJECT_ID: "getApplicantsByProjectId",
  GET_MY_RECRUITING_PROJECTS: "getMyRecruitingProjects",
  GET_MY_APPLIED_PROJECTS: "getMyAppliedProjects",
  GET_MY_IN_PROGRESS_PROJECTS: "getMyInProgressProjects",
  GET_MY_IN_PROGRESS_PROJECT_DETAIL: "getMyInProgressProjectDetail",
  GET_MY_COMPLETE_PROJECTS: "getMyCompleteProjects",
  GET_MY_COMPLETE_PROJECT_DETAIL: "getMyCompleteProjectDetail",
};
