interface CascaderOption {
  value: string;
  label: string;
  children?: CascaderOption[];
}

export const koreaRegionCascaderOptions: CascaderOption[] = [
  {
    value: "SEOUL",
    label: "서울",
    children: [
      {
        value: "SEOUL_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "SEOUL_REGION_2DEPTH_A",
        label: "강남구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_B",
        label: "강동구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_C",
        label: "강북구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_D",
        label: "강서구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_E",
        label: "관악구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_F",
        label: "광진구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_G",
        label: "구로구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_H",
        label: "금천구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_I",
        label: "노원구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_J",
        label: "도봉구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_K",
        label: "동대문구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_L",
        label: "동작구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_M",
        label: "마포구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_N",
        label: "서대문구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_O",
        label: "서초구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_P",
        label: "성동구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_Q",
        label: "성북구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_R",
        label: "송파구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_S",
        label: "양천구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_T",
        label: "영등포구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_U",
        label: "용산구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_V",
        label: "은평구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_W",
        label: "종로구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_X",
        label: "중구",
      },
      {
        value: "SEOUL_REGION_2DEPTH_Y",
        label: "중랑구",
      },
    ],
  },
  {
    value: "GYEONGGI",
    label: "경기",
    children: [
      {
        value: "GYEONGGI_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AA",
        label: "가평군",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AB",
        label: "고양시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AC",
        label: "과천시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AD",
        label: "광명시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AE",
        label: "광주시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AF",
        label: "구리시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AG",
        label: "군포시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AH",
        label: "김포시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AI",
        label: "남양주시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AJ",
        label: "동두천시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AK",
        label: "부천시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AL",
        label: "성남시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AM",
        label: "수원시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AN",
        label: "시흥시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AO",
        label: "안산시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AP",
        label: "안성시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AQ",
        label: "안양시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AR",
        label: "양주시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AS",
        label: "양평군",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AT",
        label: "여주시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AU",
        label: "연천군",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AV",
        label: "오산시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AW",
        label: "용인시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AX",
        label: "의왕시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AY",
        label: "의정부시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_AZ",
        label: "이천시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_BA",
        label: "파주시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_BB",
        label: "평택시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_BC",
        label: "포천시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_BD",
        label: "하남시",
      },
      {
        value: "GYEONGGI_REGION_2DEPTH_BE",
        label: "화성시",
      },
    ],
  },
  {
    value: "INCHEON",
    label: "인천",
    children: [
      {
        value: "INCHEON_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "INCHEON_REGION_2DEPTH_A",
        label: "강화군",
      },
      {
        value: "INCHEON_REGION_2DEPTH_B",
        label: "계양구",
      },
      {
        value: "INCHEON_REGION_2DEPTH_C",
        label: "남동구",
      },
      {
        value: "INCHEON_REGION_2DEPTH_D",
        label: "동구",
      },
      {
        value: "INCHEON_REGION_2DEPTH_E",
        label: "미추홀구",
      },
      {
        value: "INCHEON_REGION_2DEPTH_F",
        label: "부평구",
      },
      {
        value: "INCHEON_REGION_2DEPTH_G",
        label: "서구",
      },
      {
        value: "INCHEON_REGION_2DEPTH_H",
        label: "연수구",
      },
      {
        value: "INCHEON_REGION_2DEPTH_I",
        label: "웅진구",
      },
      {
        value: "INCHEON_REGION_2DEPTH_J",
        label: "중구",
      },
    ],
  },
  {
    value: "BUSAN",
    label: "부산",
    children: [
      {
        value: "BUSAN_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "BUSAN_REGION_2DEPTH_A",
        label: "강서구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_B",
        label: "금정구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_C",
        label: "기장군",
      },
      {
        value: "BUSAN_REGION_2DEPTH_D",
        label: "남구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_E",
        label: "동구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_F",
        label: "동래구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_G",
        label: "부산진구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_H",
        label: "북구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_I",
        label: "사상구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_J",
        label: "사하구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_K",
        label: "서구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_L",
        label: "수영구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_M",
        label: "연제구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_N",
        label: "영도구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_O",
        label: "중구",
      },
      {
        value: "BUSAN_REGION_2DEPTH_P",
        label: "해운대구",
      },
    ],
  },
  {
    value: "DAEGU",
    label: "대구",
    children: [
      {
        value: "DAEGU_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "DAEGU_REGION_2DEPTH_A",
        label: "남구",
      },
      {
        value: "DAEGU_REGION_2DEPTH_B",
        label: "달서구",
      },
      {
        value: "DAEGU_REGION_2DEPTH_C",
        label: "달성군",
      },
      {
        value: "DAEGU_REGION_2DEPTH_D",
        label: "동구",
      },
      {
        value: "DAEGU_REGION_2DEPTH_E",
        label: "북구",
      },
      {
        value: "DAEGU_REGION_2DEPTH_F",
        label: "서구",
      },
      {
        value: "DAEGU_REGION_2DEPTH_G",
        label: "수성구",
      },
      {
        value: "DAEGU_REGION_2DEPTH_H",
        label: "중구",
      },
    ],
  },
  {
    value: "DAEJEON",
    label: "대전",
    children: [
      {
        value: "DAEJEON_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "DAEJEON_REGION_2DEPTH_A",
        label: "대덕구",
      },
      {
        value: "DAEJEON_REGION_2DEPTH_B",
        label: "동구",
      },
      {
        value: "DAEJEON_REGION_2DEPTH_C",
        label: "서구",
      },
      {
        value: "DAEJEON_REGION_2DEPTH_D",
        label: "유성구",
      },
      {
        value: "DAEJEON_REGION_2DEPTH_E",
        label: "중구",
      },
    ],
  },
  {
    value: "GWANGJU",
    label: "광주",
    children: [
      {
        value: "GWANGJU_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "GWANGJU_REGION_2DEPTH_A",
        label: "광산구",
      },
      {
        value: "GWANGJU_REGION_2DEPTH_B",
        label: "남구",
      },
      {
        value: "GWANGJU_REGION_2DEPTH_C",
        label: "동구",
      },
      {
        value: "GWANGJU_REGION_2DEPTH_D",
        label: "북구",
      },
      {
        value: "GWANGJU_REGION_2DEPTH_E",
        label: "서구",
      },
    ],
  },
  {
    value: "ULSAN",
    label: "울산",
    children: [
      {
        value: "ULSAN_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "ULSAN_REGION_2DEPTH_A",
        label: "남구",
      },
      {
        value: "ULSAN_REGION_2DEPTH_B",
        label: "동구",
      },
      {
        value: "ULSAN_REGION_2DEPTH_C",
        label: "북구",
      },
      {
        value: "ULSAN_REGION_2DEPTH_D",
        label: "울주군",
      },
      {
        value: "ULSAN_REGION_2DEPTH_E",
        label: "중구",
      },
    ],
  },
  {
    value: "SEJONG",
    label: "세종",
    children: [
      {
        value: "SEJONG_REGION_2DEPTH_ALL",
        label: "전체",
      },
    ],
  },
  {
    value: "GANGWON",
    label: "강원",
    children: [
      {
        value: "GANGWON_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "GANGWON_REGION_2DEPTH_A",
        label: "강릉시",
      },
      {
        value: "GANGWON_REGION_2DEPTH_B",
        label: "고성군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_C",
        label: "동해시",
      },
      {
        value: "GANGWON_REGION_2DEPTH_D",
        label: "삼척시",
      },
      {
        value: "GANGWON_REGION_2DEPTH_E",
        label: "속초시",
      },
      {
        value: "GANGWON_REGION_2DEPTH_F",
        label: "양구군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_G",
        label: "양양군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_H",
        label: "영월군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_I",
        label: "원주시",
      },
      {
        value: "GANGWON_REGION_2DEPTH_J",
        label: "인제군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_K",
        label: "정선군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_L",
        label: "철원군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_M",
        label: "춘천시",
      },
      {
        value: "GANGWON_REGION_2DEPTH_N",
        label: "태백시",
      },
      {
        value: "GANGWON_REGION_2DEPTH_O",
        label: "평창군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_P",
        label: "홍천군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_Q",
        label: "화천군",
      },
      {
        value: "GANGWON_REGION_2DEPTH_R",
        label: "횡성군",
      },
    ],
  },
  {
    value: "CHUNGBUK",
    label: "충북",
    children: [
      {
        value: "CHUNGBUK_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_A",
        label: "괴산군",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_B",
        label: "단양군",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_C",
        label: "보은군",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_D",
        label: "영동군",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_E",
        label: "옥천군",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_F",
        label: "음성군",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_G",
        label: "제천시",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_H",
        label: "증평군",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_I",
        label: "진천군",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_J",
        label: "청주시",
      },
      {
        value: "CHUNGBUK_REGION_2DEPTH_K",
        label: "충주시",
      },
    ],
  },
  {
    value: "CHUNGNAM",
    label: "충남",
    children: [
      {
        value: "CHUNGNAM_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_A",
        label: "계룡시",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_B",
        label: "공주시",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_C",
        label: "금산군",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_D",
        label: "논산시",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_E",
        label: "당진시",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_F",
        label: "보령시",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_G",
        label: "부여군",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_H",
        label: "서산시",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_I",
        label: "서천군",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_J",
        label: "아산시",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_K",
        label: "예산군",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_L",
        label: "천안시",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_M",
        label: "청양군",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_N",
        label: "태안군",
      },
      {
        value: "CHUNGNAM_REGION_2DEPTH_O",
        label: "홍성군",
      },
    ],
  },
  {
    value: "JEONBUK",
    label: "전북",
    children: [
      {
        value: "JEONBUK_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_A",
        label: "고창군",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_B",
        label: "군산시",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_C",
        label: "김제시",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_D",
        label: "남원시",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_E",
        label: "무주군",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_F",
        label: "부안군",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_G",
        label: "순창군",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_H",
        label: "완주군",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_I",
        label: "익산시",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_J",
        label: "임실군",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_K",
        label: "장수군",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_L",
        label: "전주시",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_M",
        label: "정읍시",
      },
      {
        value: "JEONBUK_REGION_2DEPTH_N",
        label: "진안군",
      },
    ],
  },
  {
    value: "JEONNAM",
    label: "전남",
    children: [
      {
        value: "JEONNAM_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_A",
        label: "강진군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_B",
        label: "고흥군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_C",
        label: "곡성군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_D",
        label: "광양시",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_E",
        label: "구례군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_F",
        label: "나주시",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_G",
        label: "담양군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_H",
        label: "목포시",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_I",
        label: "무안군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_J",
        label: "보성군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_K",
        label: "순천시",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_L",
        label: "신안군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_M",
        label: "여수시",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_N",
        label: "영관군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_O",
        label: "영암군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_P",
        label: "완도군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_Q",
        label: "장성군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_R",
        label: "장흥군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_S",
        label: "진도군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_T",
        label: "함평군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_U",
        label: "해남군",
      },
      {
        value: "JEONNAM_REGION_2DEPTH_V",
        label: "화순군",
      },
    ],
  },
  {
    value: "GYEONGBUK",
    label: "경북",
    children: [
      {
        value: "GYEONGBUK_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_A",
        label: "경산시",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_B",
        label: "경주시",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_C",
        label: "고령군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_D",
        label: "구미시",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_E",
        label: "군위군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_F",
        label: "김천시",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_G",
        label: "문경시",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_H",
        label: "봉화군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_I",
        label: "상주시",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_J",
        label: "성주군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_K",
        label: "안동시",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_L",
        label: "영덕군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_M",
        label: "영양군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_N",
        label: "영주시",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_O",
        label: "영천시",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_P",
        label: "예천군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_Q",
        label: "울릉군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_R",
        label: "울진군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_S",
        label: "의성군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_T",
        label: "청도군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_U",
        label: "청송군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_V",
        label: "칠곡군",
      },
      {
        value: "GYEONGBUK_REGION_2DEPTH_W",
        label: "포항시",
      },
    ],
  },
  {
    value: "GYEONGNAM",
    label: "경남",
    children: [
      {
        value: "GYEONGNAM_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_A",
        label: "거제시",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_B",
        label: "거창군",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_C",
        label: "고성군",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_D",
        label: "김해시",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_E",
        label: "남해군",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_F",
        label: "밀양시",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_G",
        label: "사천시",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_H",
        label: "산청군",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_I",
        label: "양산시",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_J",
        label: "의령군",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_K",
        label: "진주시",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_L",
        label: "창년군",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_M",
        label: "창원시",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_N",
        label: "통영시",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_O",
        label: "하동군",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_P",
        label: "함안군",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_Q",
        label: "함양군",
      },
      {
        value: "GYEONGNAM_REGION_2DEPTH_R",
        label: "합천군",
      },
    ],
  },
  {
    value: "JEJU",
    label: "제주",
    children: [
      {
        value: "JEJU_REGION_2DEPTH_ALL",
        label: "전체",
      },
      {
        value: "JEJU_REGION_2DEPTH_A",
        label: "제주시",
      },
      {
        value: "JEJU_REGION_2DEPTH_B",
        label: "서귀포시",
      },
    ],
  },
];
