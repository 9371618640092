import styled from "styled-components";
import {
  EMAIL_REGEX,
  SIX_DIGIT_NUMBER_REGEX,
} from "../../../constants/regex.constant";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isHandleBackModalOpenState } from "../../../atoms/modalState";
import { getLocalStorage } from "../../../helpers/storage.helper";
import { localStorageKey } from "../../../constants/localStorageKey.constant";
import { IOauthSignUpToken } from "../../../types";
import { pageRoute } from "../../../constants/pageRoute.constant";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Space } from "antd";
import {
  useOauthSignUp,
  useSendSignUpVerifyStringEmail,
} from "../../../hooks/queries/useAccountQuery";
import { signUpAuthTokenIdState } from "../../../atoms/accountState";
import { isGlobalLoadingState } from "../../../atoms/loadingState";

const Title = styled.div`
  margin: 10px;
  font-size: ${(props) => props.theme.textSize.xLarge};
  text-align: center;
`;

const SubTitle = styled.div`
  margin: 10px;
  color: ${(props) => props.theme.textColor.gray};
  font-size: ${(props) => props.theme.textSize.medium};
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 560px;
  width: 635px;
  padding-top: 40px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.backgroundColor.white};
`;

const StyledLabel = styled.span`
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const StyledInput = styled(Input)`
  height: 52px;
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const EmailWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  height: 52px;
  width: 58px;
  margin-top: 31px;
  margin-left: 12px;
  background-color: ${(props) => props.theme.backgroundColor.black};
  color: ${({ theme }) => theme.textColor.white};
`;

const CheckboxWrapper = styled.div`
  margin: 40px 20px;
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: ${(props) => props.theme.textSize.small};
`;

interface IFormInput {
  name: string;
  email?: string;
  isRequiredTermsAgreed: boolean;
  isMarketingEmailReceive: boolean;
  verifyString?: string;
}

function SignUp() {
  const [form] = Form.useForm<IFormInput>();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] =
    useRecoilState(isGlobalLoadingState);
  const [isVerifyEmailSent, setIsVerifyEmailSent] = useState(false);
  const signUpAuthTokenId = useRecoilValue(signUpAuthTokenIdState);
  const setIsHandleBackModalOpen = useSetRecoilState(
    isHandleBackModalOpenState
  );
  const sendSignUpVerifyStringEmailMutation = useSendSignUpVerifyStringEmail();
  const { mutate, isLoading } = useOauthSignUp();
  const navigate = useNavigate();
  const oauthSignUpToken = getLocalStorage<IOauthSignUpToken>(
    localStorageKey.OAUTH_SIGN_UP_TOKEN
  );

  useEffect(() => {
    if (!oauthSignUpToken) navigate(pageRoute.SIGN_IN);
  }, [oauthSignUpToken, navigate]);

  useEffect(() => {
    return () => {
      setIsGlobalLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading && !isGlobalLoading) setIsGlobalLoading(true);
    if (!isLoading && isGlobalLoading) setIsGlobalLoading(false);
    // eslint-disable-next-line
  }, [isLoading]);

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setIsEmailValid(EMAIL_REGEX.test(email));
  };

  const handleVerifyEmailSend = () => {
    const email = form.getFieldValue("email");
    sendSignUpVerifyStringEmailMutation.mutate({ email });

    alert("인증번호를 메일로 발송했습니다.");
    setIsVerifyEmailSent(true);
  };

  function handleFormChange() {
    const values = form.getFieldsValue();
    const name = !!values.name;
    const email = !!values.email && EMAIL_REGEX.test(values.email);
    const verifyString =
      !!values.verifyString && SIX_DIGIT_NUMBER_REGEX.test(values.verifyString);
    const isRequiredTermsAgreed = values.isRequiredTermsAgreed || false;

    const validForm: boolean = isEmailInputNeeded
      ? name && email && verifyString && isRequiredTermsAgreed
      : name && isRequiredTermsAgreed;

    setIsFormValid(validForm);
  }

  const onFinish = (values: IFormInput) => {
    const { isMarketingEmailReceive, email, name, verifyString } = values;
    const authTokenId = signUpAuthTokenId?.authTokenId;

    mutate({
      authToken,
      isMarketingEmailReceive,
      email,
      name,
      authTokenId,
      verifyString,
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    const namePath = errorInfo.errorFields[0].name;
    form.getFieldInstance(namePath)?.focus();
  };

  if (!oauthSignUpToken) return null;

  const { authToken, email } = oauthSignUpToken!;
  const isEmailInputNeeded = !email;

  return (
    <Container>
      <Title children="신규가입" />
      <SubTitle>
        원활한 서비스 이용을 위해
        <br />
        정보를 입력해주세요.
      </SubTitle>

      <Form
        form={form}
        initialValues={{
          email,
          isRequiredTermsAgreed: false,
          isMarketingEmailReceive: false,
        }}
        layout="vertical"
        variant="outlined"
        style={{
          width: "100%",
          padding: "20px 40px",
        }}
        onValuesChange={handleFormChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={<StyledLabel>이름</StyledLabel>}
          name={["name"]}
          rules={[{ required: true, message: "이름을 입력해주세요." }]}
        >
          <StyledInput
            placeholder="이름을 입력해주세요."
            autoComplete="off"
            onPressEnter={handlePressEnter}
          />
        </Form.Item>

        <EmailWrapper>
          <Form.Item
            label={<StyledLabel>이메일</StyledLabel>}
            name={["email"]}
            rules={[{ required: true, message: "이메일을 입력해주세요." }]}
            style={{ flex: 1, marginBottom: 0 }}
          >
            <StyledInput
              disabled={!isEmailInputNeeded}
              placeholder="이메일을 입력해주세요."
              autoComplete="off"
              onChange={handleEmailChange}
              onPressEnter={handlePressEnter} // TODO
            />
          </Form.Item>

          {isEmailInputNeeded && (
            <StyledButton
              disabled={!isEmailValid}
              onClick={handleVerifyEmailSend}
              children="인증"
            />
          )}
        </EmailWrapper>

        {isVerifyEmailSent && (
          <Form.Item
            name={["verifyString"]}
            rules={[
              {
                required: true,
                message: "메일로 전송된 인증번호를 입력해주세요.", // TODO 인증번호 입력 시간을 초과했습니다. 다시 이메일 인증을 진행해주세요.
              },
            ]}
            style={{ margin: "10px 0px" }}
          >
            <StyledInput
              placeholder="인증번호 6자리를 입력해주세요."
              autoComplete="off"
              onPressEnter={handlePressEnter}
            />
          </Form.Item>
        )}

        <CheckboxWrapper>
          <Form.Item
            name={["isRequiredTermsAgreed"]}
            valuePropName="checked"
            style={{ margin: 0 }}
          >
            <StyledCheckbox>
              {/* // TODO */}
              [필수] 이용약관과 개인처리방침에 동의합니다.
            </StyledCheckbox>
          </Form.Item>
          <Form.Item
            name={["isMarketingEmailReceive"]}
            valuePropName="checked"
            style={{ margin: 0 }}
          >
            <StyledCheckbox>
              [선택] 프로젝트 관련한 알림 메일을 발송해드려요.
            </StyledCheckbox>
          </Form.Item>
        </CheckboxWrapper>

        <Form.Item>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
            }}
            size="middle"
          >
            <Button
              type="default"
              htmlType="button"
              onClick={() => setIsHandleBackModalOpen(true)}
              style={{ height: "44px", width: "110px" }}
            >
              이전
            </Button>
            <Button
              disabled={!isFormValid}
              type="primary"
              htmlType="submit"
              style={{ height: "44px", width: "110px" }}
            >
              가입하기
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Container>
  );
}

export default SignUp;
