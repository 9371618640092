import { Button, Checkbox, Form, Input, Modal, Select, Space } from "antd";
import styled from "styled-components";
import noticeIcon from "../../../assets/icons/notice.png";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { isGlobalLoadingState } from "../../../atoms/loadingState";
import socialKakao from "../../../assets/icons/social-kakao.png";
import socialGoogle from "../../../assets/icons/social-google.png";
import { userState } from "../../../atoms/accountState";
import {
  IUpdateUser,
  useGetUser,
  useUpdateUser,
} from "../../../hooks/queries/useAccountQuery";
import { Provider } from "../../../types";
import { jobOptions } from "../../../constants/selectProps.constant";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 754px;
  padding-top: 34px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.backgroundColor.white};
`;

const Title = styled.h1`
  width: 100%;
  padding: 0px 32px;
  font-size: ${(props) => props.theme.textSize.large};
`;

const Notice = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 56px;
  padding: 24px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.backgroundColor.lightCoolGray};
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const NoticeIcon = styled.img`
  height: 20px;
  width: auto;
`;

const StyledLabel = styled.span`
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
`;

const SocialIcon = styled.img`
  height: 16px;
  width: auto;
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: ${({ theme }) => theme.textSize.teeny};
  color: ${({ theme }) => theme.textColor.gray};
`;

const StyledInput = styled(Input)`
  height: 52px;
  font-size: ${(props) => props.theme.textSize.tiny};
`;

const StyledSelect = styled(Select)`
  height: 52px;
  font-size: ${(props) => props.theme.textSize.tiny};
  .ant-select-selection-item {
    font-size: ${(props) => props.theme.textSize.tiny};
  }
`;

const StyledTextArea = styled(Input.TextArea)`
  font-size: ${(props) => props.theme.textSize.tiny};
  padding: 16px 12px;
`;

// const Item = styled.div`
//   margin: 28px 0px;
// `;

// const ItemLabel = styled.div`
//   padding-bottom: 8px;
// `;

// const ItemValue = styled.div`
//   color: ${({ theme }) => theme.textColor.gray};
// `;

const ModalContent = styled.p`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.darkerGray};
`;

function Mypage() {
  const [form] = Form.useForm<IUpdateUser>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] =
    useRecoilState(isGlobalLoadingState);
  const user = useRecoilValue(userState);
  const { refetch } = useGetUser({ enabled: false });
  const { mutate, isLoading } = useUpdateUser();

  useEffect(() => {
    return () => {
      setIsGlobalLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleFormReset();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading && !isGlobalLoading) setIsGlobalLoading(true);
    if (!isLoading && isGlobalLoading) {
      setIsGlobalLoading(false);
      setIsConfirmModalOpen(true);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handlePressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault();
    },
    []
  );

  const handleFormReset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onFinish = (values: IUpdateUser) => {
    mutate(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    const namePath = errorInfo.errorFields[0].name;
    form.getFieldInstance(namePath)?.focus();
  };

  if (!user) return null;
  const { email, name, job, yearOfJob, introduction, skillNameList, account } =
    user!;
  const { isMarketingEmailReceive, accountProviders } = account;
  const provider = accountProviders?.[0].provider;

  return (
    <Container>
      <Title>내 정보</Title>

      <Form
        form={form}
        initialValues={{
          isMarketingEmailReceive,
          name,
          job,
          yearOfJob,
          introduction,
          skillNameList,
        }}
        layout="vertical"
        variant="outlined"
        style={{
          width: "100%",
          padding: "30px 60px",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Notice>
          <NoticeIcon src={noticeIcon} />
          프로젝트를 진행하기 위해 내 정보를 입력해주세요.
        </Notice>

        <Form.Item
          label={<StyledLabel>이메일</StyledLabel>}
          style={{ margin: "28px 0px" }}
        >
          <EmailWrapper>
            <SocialIcon
              src={provider === Provider.GOOGLE ? socialGoogle : socialKakao}
            />
            {email}
          </EmailWrapper>
          <Form.Item
            name={["isMarketingEmailReceive"]}
            valuePropName="checked"
            noStyle
          >
            <StyledCheckbox>
              프로젝트에 대한 유용한 정보를 이메일로 받아볼게요!
            </StyledCheckbox>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={<StyledLabel>이름</StyledLabel>}
          name={["name"]}
          rules={[{ required: true, message: "이름을 입력해주세요." }]}
          style={{ margin: "28px 0px" }}
        >
          <StyledInput
            placeholder="이름을 입력해주세요."
            autoComplete="off"
            onPressEnter={handlePressEnter}
          />
        </Form.Item>

        <Form.Item
          label={<StyledLabel>직무</StyledLabel>}
          name={["job"]}
          normalize={(value) => (value === undefined ? null : value)}
          style={{ margin: "28px 0px" }}
        >
          <StyledSelect
            allowClear
            optionFilterProp="label"
            placeholder="직무를 선택해주세요."
            options={jobOptions}
          />
        </Form.Item>

        <Form.Item
          label={<StyledLabel>스킬</StyledLabel>}
          name={["skillNameList"]}
          style={{ margin: "28px 0px" }}
        >
          <StyledSelect
            mode="tags"
            placeholder="입력 후 엔터를 눌러주세요."
            // options={skillNameOptions} // TODO
          />
        </Form.Item>

        <Form.Item
          label={<StyledLabel>연차</StyledLabel>}
          name={["yearOfJob"]}
          normalize={(value) => (value === undefined ? null : value)}
          style={{ margin: "28px 0px" }}
        >
          <StyledSelect
            allowClear
            showSearch={true}
            optionFilterProp="value"
            placeholder="연차를 선택해주세요."
            options={Array.from({ length: 35 }, (_, i) => ({
              label: i === 34 ? `${i + 1}년 이상` : `${i + 1}년`,
              value: i + 1,
            }))}
          />
        </Form.Item>

        <Form.Item
          label={<StyledLabel>내 소개</StyledLabel>}
          name={["introduction"]}
          style={{ margin: "28px 0px" }}
        >
          <StyledTextArea
            placeholder="간단한 내 소개를 작성해주세요."
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        </Form.Item>

        {/* // TODO */}
        {/* <Item>
          <ItemLabel>진행한 프로젝트 건수</ItemLabel>
          <ItemValue>0건</ItemValue>
        </Item> */}

        <Form.Item>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              paddingTop: "30px",
            }}
            size="middle"
          >
            <Button
              type="default"
              htmlType="button"
              onClick={handleFormReset}
              style={{ height: "44px", width: "110px" }}
            >
              취소
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ height: "44px", width: "110px" }}
            >
              저장
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <Modal
        open={isConfirmModalOpen}
        footer={[
          <Button
            key="confirm"
            type="primary"
            onClick={() => setIsConfirmModalOpen(false)}
          >
            확인
          </Button>,
        ]}
        onCancel={() => setIsConfirmModalOpen(false)}
        closable={false}
        width={400}
      >
        <ModalContent>수정된 정보가 저장되었어요.</ModalContent>
      </Modal>
    </Container>
  );
}

export default Mypage;
