import dayjs from "dayjs";

export const isValidStringDate = (stringDate: string): boolean => {
  const date = new Date(stringDate);

  return !isNaN(date.getTime());
};

/**
 * isoString을 YYYY.MM.DD와 같은 형태의 stringifiedDate로 반환
 */
export const isoStringToDateTypeA = function (isoString?: string): string {
  if (!isoString) return "-";

  if (!isValidStringDate(isoString)) return "-";

  return dayjs(isoString).format("YYYY.MM.DD");
};

/**
 * isoString을 MM.DD와 같은 형태의 stringifiedDate로 반환
 */
export const isoStringToDateTypeB = function (isoString?: string): string {
  if (!isoString) return "-";

  if (!isValidStringDate(isoString)) return "-";

  return dayjs(isoString).format("MM.DD");
};

/**
 * dayjs를 MM.DD와 같은 형태의 stringifiedDate로 반환
 */
export const dayjsToDateTypeB = function (date?: dayjs.Dayjs): string {
  if (!date) return "-";

  if (!dayjs.isDayjs(date)) return "-";

  return date.format("MM.DD");
};

/**
 * ISO 문자열을 Day.js 객체로 변환
 */
export const isoStringToDayjs = function (
  isoString?: string
): dayjs.Dayjs | undefined {
  if (!isoString) return undefined;

  if (!isValidStringDate(isoString)) return undefined;

  return dayjs(isoString);
};

/**
 * Day.js 객체를 ISO 문자열로 변환
 */
export const dayjsToIsoString = function (date?: any): string | undefined {
  if (!date || !dayjs.isDayjs(date)) return undefined;

  return date.toISOString();
};
