import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import RecoilizeDebugger from "recoilize";
import App from "./App";
import { theme } from "./styles/theme";
import { GlobalStyle } from "./styles/global";
import { commonConstants } from "./constants/common.constant";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0, // default
      cacheTime: 5 * 60 * 1000, // default
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true, // default
      refetchInterval: false, // default
      enabled: true, // default
    },
    mutations: {
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <RecoilRoot>
    {process.env.NODE_ENV === commonConstants.nodeEnvs.DEVELOPMENT && (
      <RecoilizeDebugger />
    )}
    <QueryClientProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </RecoilRoot>
  // </React.StrictMode>
);
