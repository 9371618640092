import axios, { HttpStatusCode } from "axios";
import { localStorageKey } from "../constants/localStorageKey.constant";
import { getLocalStorage, removeLocalStorage } from "./storage.helper";

const axiosInstance = axios.create({
  // TODO
  // timeout: 1000,
  // headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const bearerToken = getLocalStorage<string>(localStorageKey.BEARER_TOKEN);
    if (bearerToken) config.headers["Authorization"] = bearerToken;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const statusCode = error.response?.status;

    if (
      statusCode &&
      statusCode >= 400 &&
      statusCode !== HttpStatusCode.NotFound
    ) {
      const errorMessage =
        error.response?.data?.message ||
        `Error ${statusCode}: ${error.message}`;

      alert(errorMessage);
    }

    if (
      statusCode === HttpStatusCode.Unauthorized ||
      statusCode === HttpStatusCode.Forbidden // TODO 서버랑 맞춰야 함
    ) {
      removeLocalStorage(localStorageKey.BEARER_TOKEN);
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

const axiosRequest = async function (
  method: "get" | "post" | "put" | "delete",
  url: string,
  params?: Record<string, any>,
  data?: Record<string, any>,
  headers?: Record<string, any>
) {
  return await axiosInstance({
    method,
    url,
    params,
    data,
    headers,
  });
};

export const httpGet = async function (
  url: string,
  params?: Record<string, any>,
  headers?: Record<string, any>
): Promise<any> {
  return await axiosRequest("get", url, params, undefined, headers);
};

export const httpPost = async function (
  url: string,
  params?: Record<string, any>,
  data?: Record<string, any>,
  headers?: Record<string, any>
): Promise<any> {
  return await axiosRequest("post", url, params, data, headers);
};

export const httpPut = async function (
  url: string,
  data?: Record<string, any>,
  headers?: Record<string, any>
): Promise<any> {
  return await axiosRequest("put", url, undefined, data, headers);
};

export const httpDelete = async function (
  url: string,
  data?: Record<string, any>,
  headers?: Record<string, any>
): Promise<any> {
  return await axiosRequest("delete", url, undefined, data, headers);
};
