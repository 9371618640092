import styled from "styled-components";
import { Link, matchPath, useLocation } from "react-router-dom";
import { pageRoute } from "../../../constants/pageRoute.constant";
import { localStorageKey } from "../../../constants/localStorageKey.constant";
import { getLocalStorage } from "../../../helpers/storage.helper";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 1000;
  padding: 20px 60px;
  color: ${(props) => props.theme.textColor.black};
  font-size: ${(props) => props.theme.textSize.small};
  font-weight: ${(props) => props.theme.fontWeight.light};
  background-color: ${(props) => props.theme.backgroundColor.white};
  white-space: nowrap;
`;

const Logo = styled.div`
  font-size: ${(props) => props.theme.textSize.large};
  font-weight: ${(props) => props.theme.fontWeight.extraBold};
`;

const MenuSection = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 50px;
`;

const UserSection = styled.div`
  display: flex;
  gap: 20px;
  margin-left: auto;
`;

const NavLink = styled(Link)<{ $isActive: boolean }>`
  text-decoration: none;
  font-weight: ${({ $isActive, theme }) =>
    $isActive ? theme.fontWeight.medium : theme.fontWeight.regular};
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.textColor.black : theme.textColor.gray};
  &:hover {
    text-decoration: underline;
  }
`;

function Header() {
  const isAuthenticated = getLocalStorage(localStorageKey.BEARER_TOKEN);
  const location = useLocation();

  const pathname = location.pathname;
  const isMyProject = !!matchPath(pageRoute.MY_PROJECT + "/*", pathname);
  const isMypage = !!matchPath(pageRoute.MYPAGE + "/*", pathname);

  return (
    <Nav>
      <Logo>
        <Link to={pageRoute.HOME}>SubTask</Link>
      </Logo>
      <MenuSection>
        <NavLink
          to={pageRoute.PROJECT_REGISTER}
          $isActive={location.pathname === pageRoute.PROJECT_REGISTER}
        >
          프로젝트 등록
        </NavLink>
        <NavLink
          to={pageRoute.PROJECT_LIST}
          $isActive={location.pathname === pageRoute.PROJECT_LIST}
        >
          프로젝트 찾기
        </NavLink>
        {/* <NavLink
          to={pageRoute.GUIDE}
          $isActive={location.pathname === pageRoute.GUIDE}
        >
          이용방법
        </NavLink> */}
      </MenuSection>
      <UserSection>
        {isAuthenticated ? (
          <>
            <NavLink to={pageRoute.MY_PROJECT} $isActive={isMyProject}>
              내 프로젝트 관리
            </NavLink>
            <NavLink to={pageRoute.MYPAGE} $isActive={isMypage}>
              마이페이지
            </NavLink>
          </>
        ) : (
          <NavLink
            to={pageRoute.SIGN_IN}
            $isActive={location.pathname === pageRoute.SIGN_IN}
          >
            로그인
          </NavLink>
        )}
      </UserSection>
    </Nav>
  );
}

export default Header;
