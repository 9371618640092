export enum Job {
  BACKEND_DEVELOPER = "BACKEND_DEVELOPER",
  FRONTEND_DEVELOPER = "FRONTEND_DEVELOPER",
  PLANNER = "PLANNER",
  DESIGNER = "DESIGNER",
  PROJECT_MANAGER = "PROJECT_MANAGER",
}

export const jobViewer = (job: Job): string => {
  switch (job) {
    case Job.BACKEND_DEVELOPER:
      return "백엔드";
    case Job.FRONTEND_DEVELOPER:
      return "프론트엔드";
    case Job.PLANNER:
      return "기획";
    case Job.DESIGNER:
      return "디자인";
    case Job.PROJECT_MANAGER:
      return "PM";
    default:
      return job;
  }
};

export enum Provider {
  GOOGLE = "GOOGLE",
  KAKAO = "KAKAO",
}

export interface IAccountProvider {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  providerId: string;
  provider: Provider;
  service: string;
}

export interface IAccount {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  service: string;
  signInFailCount: number;
  passwordChangedAt?: string;
  passwordChangePendedAt?: string;
  requiredTermsAgreedAt?: string;
  lockedAt?: string;
  isMfaOn: boolean;
  isActivated: boolean;
  isSignUpComplete: boolean;
  isTemporaryPassword: boolean;
  isMarketingEmailReceive: boolean;
  accountProviders?: IAccountProvider[];
}

export interface IUser {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  aId: string;
  email?: string;
  name?: string;
  job?: Job;
  yearOfJob?: number;
  introduction?: string;
  account: IAccount;
  skillNameList: string[];
}

export interface IOauthSignUpToken {
  authToken: string;
  email?: string;
}

export interface ISignUpAuthTokenId {
  authTokenId: string;
}
