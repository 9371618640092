import { atom, selector } from "recoil";
import { ATOM_KEY, SELECTOR_KEY } from "../constants/atomKey.constant";
import { persistAtom } from "./recoilPersist/persistAtom";
import { ISignUpAuthTokenId, IUser } from "../types";

export const userState = atom<IUser | null>({
  key: ATOM_KEY.USER_STATE,
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const signUpAuthTokenIdState = atom<ISignUpAuthTokenId | null>({
  key: ATOM_KEY.SIGN_UP_AUTH_TOKEN_ID_STATE,
  default: null,
});

export const isProfileCompleteSelector = selector<boolean>({
  key: SELECTOR_KEY.IS_PROFILE_COMPLETE_SELECTOR,
  get: ({ get }) => {
    const user = get(userState);

    if (!user) {
      return false;
    }

    const { email, name, job, yearOfJob, skillNameList } = user;

    if (email && name && job && yearOfJob && skillNameList.length > 0) {
      return true;
    }

    return false;
  },
});
