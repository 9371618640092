import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { queryKey } from "../../constants/queryKey.constant";
import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../../helpers/httpRequest.helper";
import {
  defaultMyCompleteProjectDetail,
  defaultMyInProgressProjectDetail,
  defaultProjectDetail,
  IApplicantList,
  IMyApplicationInfo,
  IMyAppliedProjectList,
  IMyCompleteProjectDetail,
  IMyCompleteProjectList,
  IMyInProgressProjectDetail,
  IMyInProgressProjectList,
  IMyRecruitingProjectList,
  IProjectDetail,
  IProjectList,
  Job,
  ProjectWorkerProgressStatus,
} from "../../types";
import {
  defaultList,
  IPagination,
  ISubtaskServerHttpError,
} from "../../types/common.type";
import {
  jobOptions,
  platformOptions,
} from "../../constants/selectProps.constant";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router-dom";
import { pageRoute } from "../../constants/pageRoute.constant";
import { ENV } from "../../env";

const { SUBTASK_SERVER_URL } = ENV;

interface IUseGetRecruitingProjects {
  enabled?: boolean;
  params?: IPagination & IUseGetRecruitingProjectsParams;
}

export interface IUseGetRecruitingProjectsParams {
  job?: string;
  projectPlatform?: string;
  projectName?: string;
}

export const useGetRecruitingProjects = ({
  enabled = true,
  params,
}: IUseGetRecruitingProjects): UseQueryResult<
  IProjectList,
  ISubtaskServerHttpError
> => {
  if (params?.job && !jobOptions?.some((option) => option.value === params.job))
    delete params.job;
  if (
    params?.projectPlatform &&
    !platformOptions?.some((option) => option.value === params.projectPlatform)
  )
    delete params.projectPlatform;

  return useQuery(
    queryKey.GET_RECRUITING_PROJECTS,
    async () => {
      return httpGet(SUBTASK_SERVER_URL + "/v1/recruiting-projects", params)
        .then((response) => response.data)
        .catch((error) => {
          if (error.status === HttpStatusCode.NotFound) return defaultList;
          else throw error;
        });
    },
    {
      placeholderData: defaultList,
      enabled,
    }
  );
};

interface ICreateProject {
  projectName: string;
  projectPlatformList: string[];
  meetingChannel: string;
  region1DepthCodeItemName?: string;
  region2DepthCodeItemName?: string;
  meetingSchedule?: string;
  desiredDeadlineAt: string;
  description: string;
  generateProjectTaskList: IGenerateProjectTask[];
}

interface IGenerateProjectTask {
  job: Job;
  isProjectRegistrantTask: boolean;
  recruitsNumber: number;
  jobDescription?: string;
}

export const useCreateProject = () => {
  const navigate = useNavigate();

  return useMutation(
    async (data: ICreateProject) => {
      return httpPost(SUBTASK_SERVER_URL + "/v1/projects", undefined, data);
    },
    {
      onSuccess: ({ data }) => {
        const projectId = data.row.id;
        navigate(`${pageRoute.PROJECT_LIST}/${projectId}`);
      },
    }
  );
};

interface IUpdateProject {
  projectName: string;
  projectPlatformList: string[];
  meetingChannel: string;
  region1DepthCodeItemName?: string;
  region2DepthCodeItemName?: string;
  meetingSchedule?: string;
  desiredDeadlineAt: string;
  description: string;
  generateProjectTaskList: IGenerateProjectTask[];
}

export const useUpdateProject = () => {
  const navigate = useNavigate();

  return useMutation(
    async ({
      projectId,
      data,
    }: {
      projectId: string;
      data: IUpdateProject;
    }) => {
      return httpPut(
        SUBTASK_SERVER_URL +
          `/v1/workers/this/my-recruiting-projects/${projectId}`,
        data
      );
    },
    {
      onSuccess: ({ data }) => {
        const projectId = data.row.id;
        navigate(`${pageRoute.PROJECT_LIST}/${projectId}`);
      },
    }
  );
};

export const useGetRecruitingProjectDetail = ({
  enabled = true,
  projectId,
}: {
  enabled?: boolean;
  projectId: string;
}): UseQueryResult<IProjectDetail, ISubtaskServerHttpError> => {
  const navigate = useNavigate();

  return useQuery(
    `${queryKey.GET_RECRUITING_PROJECT_DETAIL}_${projectId}`,
    async () => {
      return httpGet(
        SUBTASK_SERVER_URL + `/v1/recruiting-projects/${projectId}`
      ).then((response) => response.data.row);
    },
    {
      onError: ({ status, response }) => {
        if (status === HttpStatusCode.NotFound) {
          alert(response?.data?.message);

          navigate(pageRoute.PROJECT_LIST);
        }
      },
      placeholderData: defaultProjectDetail,
      enabled,
    }
  );
};

export const useGetMyApplicationInfoByProjectId = ({
  enabled = true,
  projectId,
}: {
  enabled?: boolean;
  projectId: string;
}): UseQueryResult<IMyApplicationInfo, ISubtaskServerHttpError> => {
  return useQuery(
    `${queryKey.GET_MY_APPLICATION_INFO_BY_PROJECT_ID}_${projectId}`,
    async () => {
      return httpGet(
        SUBTASK_SERVER_URL +
          `/v1/recruiting-projects/${projectId}/my-application-info`
      ).then((response) => response.data.row);
    },
    { enabled }
  );
};

interface IApplyForProject {
  memo: string;
  projectId: string;
}

export const useApplyForProjectById = () => {
  return useMutation(
    async ({
      projectId,
      data,
    }: {
      projectId: string;
      data: IApplyForProject;
    }) => {
      return httpPost(
        SUBTASK_SERVER_URL + `/v1/recruiting-projects/${projectId}/apply`,
        undefined,
        { ...data, projectId: Number(data.projectId) }
      );
    }
  );
};

export const useGetApplicantsByProjectId = ({
  enabled = true,
  projectId,
}: {
  enabled?: boolean;
  projectId: string;
}): UseQueryResult<IApplicantList, ISubtaskServerHttpError> => {
  return useQuery(
    `${queryKey.GET_APPLICANTS_BY_PROJECT_ID}_${projectId}`,
    async () => {
      return httpGet(
        SUBTASK_SERVER_URL +
          `/v1/workers/this/my-recruiting-projects/${projectId}/applicants`
      )
        .then((response) => response.data)
        .catch((error) => {
          if (error.status === HttpStatusCode.NotFound) return defaultList;
          else throw error;
        });
    },
    { enabled }
  );
};

export const useRejectApplicantByProjectWorkerId = () => {
  return useMutation(async (data: { projectWorkerId: number }) => {
    return httpPost(
      SUBTASK_SERVER_URL +
        "/v1/workers/this/my-recruiting-project/reject-applicant",
      undefined,
      data
    );
  });
};

export const useAcceptApplicantByProjectWorkerId = () => {
  return useMutation(async (data: { projectWorkerId: number }) => {
    return httpPost(
      SUBTASK_SERVER_URL +
        "/v1/workers/this/my-recruiting-project/accept-applicant",
      undefined,
      data
    );
  });
};

export const useGetMyRecruitingProjects = ({
  params,
}: {
  params: IPagination;
}): UseQueryResult<IMyRecruitingProjectList, ISubtaskServerHttpError> => {
  return useQuery(
    queryKey.GET_MY_RECRUITING_PROJECTS,
    async () => {
      return httpGet(
        SUBTASK_SERVER_URL + "/v1/workers/this/my-recruiting-projects",
        params
      )
        .then((response) => response.data)
        .catch((error) => {
          if (error.status === HttpStatusCode.NotFound) return defaultList;
          else throw error;
        });
    },
    { placeholderData: defaultList }
  );
};

export const useGetMyAppliedProjects = ({
  params,
}: {
  params: IPagination;
}): UseQueryResult<IMyAppliedProjectList, ISubtaskServerHttpError> => {
  return useQuery(
    queryKey.GET_MY_APPLIED_PROJECTS,
    async () => {
      return httpGet(
        SUBTASK_SERVER_URL + "/v1/workers/this/my-applied-projects",
        params
      )
        .then((response) => response.data)
        .catch((error) => {
          if (error.status === HttpStatusCode.NotFound) return defaultList;
          else throw error;
        });
    },
    { placeholderData: defaultList }
  );
};

export const useDeleteMyRecruitingProject = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (projectId: number) => {
      return httpDelete(
        SUBTASK_SERVER_URL +
          `/v1/workers/this/my-recruiting-projects/${projectId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.GET_MY_RECRUITING_PROJECTS]);
      },
    }
  );
};

export const useStartMyRecruitingProject = () => {
  const navigate = useNavigate();

  return useMutation(
    async (projectId: number) => {
      return httpPost(
        SUBTASK_SERVER_URL +
          `/v1/workers/this/my-recruiting-projects/${projectId}/start-project`
      );
    },
    {
      onSuccess: (_, projectId) => {
        navigate(
          `${pageRoute.MY_PROJECT_IN_PROGRESS_PROJECT_LIST}/${projectId}`
        );
      },
    }
  );
};

export const useGetMyInProgressProjects = ({
  params,
}: {
  params: IPagination;
}): UseQueryResult<IMyInProgressProjectList, ISubtaskServerHttpError> => {
  return useQuery(
    queryKey.GET_MY_IN_PROGRESS_PROJECTS,
    async () => {
      return httpGet(
        SUBTASK_SERVER_URL + "/v1/workers/this/my-in-progress-projects",
        params
      )
        .then((response) => response.data)
        .catch((error) => {
          if (error.status === HttpStatusCode.NotFound) return defaultList;
          else throw error;
        });
    },
    { placeholderData: defaultList }
  );
};

export const useGetMyInProgressProjectDetail = (
  projectId: string
): UseQueryResult<IMyInProgressProjectDetail, ISubtaskServerHttpError> => {
  const navigate = useNavigate();

  return useQuery(
    `${queryKey.GET_MY_IN_PROGRESS_PROJECT_DETAIL}_${projectId}`,
    async () => {
      return httpGet(
        SUBTASK_SERVER_URL +
          `/v1/workers/this/my-in-progress-projects/${projectId}`
      ).then((response) => response.data.row);
    },
    {
      onError: ({ status, response }) => {
        if (status === HttpStatusCode.NotFound) {
          alert(response?.data?.message);

          navigate(-1);
        }
      },
      placeholderData: defaultMyInProgressProjectDetail,
    }
  );
};

export interface IModifyProjectWorker {
  id: number;
  projectWorkerDeadlineAt?: string;
  projectWorkerProgressStatus?: ProjectWorkerProgressStatus;
}

export interface IUpdateMyInProgressProject {
  projectIntroductionUrlList: string[];
  modifyProjectWorkers: IModifyProjectWorker[];
  introductionMediaIdList?: number[];
}

export const useUpdateMyInProgressProject = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      projectId,
      data,
    }: {
      projectId: string;
      data: IUpdateMyInProgressProject;
    }) => {
      return httpPut(
        SUBTASK_SERVER_URL +
          `/v1/workers/this/my-in-progress-projects/${projectId}`,
        data
      );
    },
    {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries([
          `${queryKey.GET_MY_IN_PROGRESS_PROJECT_DETAIL}_${projectId}`,
        ]);
      },
    }
  );
};

export const useCompleteMyInProgressProject = () => {
  const navigate = useNavigate();

  return useMutation(
    async ({ projectId }: { projectId: string }) => {
      return httpPost(
        SUBTASK_SERVER_URL +
          `/v1/workers/this/my-in-progress-projects/${projectId}/complete-project`
      );
    },
    {
      onSuccess: (_, { projectId }) => {
        navigate(`${pageRoute.MY_PROJECT_COMPLETE_PROJECT_LIST}/${projectId}`);
      },
    }
  );
};

export const useGetMyCompleteProjects = ({
  params,
}: {
  params: IPagination;
}): UseQueryResult<IMyCompleteProjectList, ISubtaskServerHttpError> => {
  return useQuery(
    queryKey.GET_MY_COMPLETE_PROJECTS,
    async () => {
      return httpGet(
        SUBTASK_SERVER_URL + "/v1/workers/this/my-complete-projects",
        params
      )
        .then((response) => response.data)
        .catch((error) => {
          if (error.status === HttpStatusCode.NotFound) return defaultList;
          else throw error;
        });
    },
    { placeholderData: defaultList }
  );
};

export const useGetMyCompleteProjectDetail = (
  projectId: string
): UseQueryResult<IMyCompleteProjectDetail, ISubtaskServerHttpError> => {
  const navigate = useNavigate();

  return useQuery(
    `${queryKey.GET_MY_COMPLETE_PROJECT_DETAIL}_${projectId}`,
    async () => {
      return httpGet(
        SUBTASK_SERVER_URL +
          `/v1/workers/this/my-complete-projects/${projectId}`
      ).then((response) => response.data.row);
    },
    {
      onError: ({ status, response }) => {
        if (status === HttpStatusCode.NotFound) {
          alert(response?.data?.message);

          navigate(-1);
        }
      },
      placeholderData: defaultMyCompleteProjectDetail,
    }
  );
};
