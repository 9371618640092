export const ATOM_KEY = {
  IS_GLOBAL_LOADING_STATE: "isGlobalLoadingState",
  IS_HANDLE_BACK_MODAL_OPEN_STATE: "isHandleBackModalOpenState",
  USER_STATE: "userState",
  SIGN_UP_AUTH_TOKEN_ID_STATE: "signUpAuthTokenIdState",
  PROJECT_LIST_STATE: " projectListState",
};

export const SELECTOR_KEY = {
  IS_PROFILE_COMPLETE_SELECTOR: "isProfileCompleteSelector",
};
