import { matchPath, useLocation } from "react-router-dom";
import SidebarMypage from "./SidebarMypage";
import { pageRoute } from "../../../constants/pageRoute.constant";
import SidebarMyProjects from "./SidebarMyProjects";

function Sidebar() {
  const location = useLocation();

  const isMypage = !!matchPath(pageRoute.MYPAGE + "/*", location.pathname);
  const isMyProjects = !!matchPath(
    pageRoute.MY_PROJECT + "/*",
    location.pathname
  );

  return isMypage ? (
    <SidebarMypage />
  ) : isMyProjects ? (
    <SidebarMyProjects />
  ) : null;
}

export default Sidebar;
