import styled from "styled-components";
import {
  useApplyForProjectById,
  useGetMyApplicationInfoByProjectId,
} from "../../../hooks/queries/useProjectQuery";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../../atoms/accountState";
import { jobViewer } from "../../../types";
import { Button, Input, message, Modal } from "antd";
import { isHandleBackModalOpenState } from "../../../atoms/modalState";
import { useCallback, useEffect, useState } from "react";
import { isGlobalLoadingState } from "../../../atoms/loadingState";

const Wrapper = styled.div`
  width: 100%;
  padding: 40px 58px 0px;
`;

const Container = styled.div`
  width: 637px;
  padding: 33px 26px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgroundColor.lightCoolGray};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.textSize.medium};
  margin-bottom: 30px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  font-size: ${(props) => props.theme.textSize.small};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 48px;
`;

const ItemField = styled.div<{ required?: boolean }>`
  ${({ required }) =>
    required &&
    `&::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }`}
`;

const ItemValue = styled.div`
  color: ${(props) => props.theme.textColor.gray};
  white-space: pre-line;
`;

const StyledTextArea = styled(Input.TextArea)`
  font-size: ${(props) => props.theme.textSize.tiny};
  padding: 16px 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 40px;
`;

const ModalContent = styled.p`
  font-size: ${(props) => props.theme.textSize.tiny};
  color: ${({ theme }) => theme.textColor.darkerGray};
`;

interface IProps {
  projectId: string;
}

function MyApplicationInfo({ projectId }: IProps) {
  const [memo, setMemo] = useState("");
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] =
    useRecoilState(isGlobalLoadingState);
  const user = useRecoilValue(userState)!;
  const setIsHandleBackModalOpen = useSetRecoilState(
    isHandleBackModalOpenState
  );
  const {
    data: myApplicationInfo,
    isFetching,
    refetch,
  } = useGetMyApplicationInfoByProjectId({
    projectId,
  });
  const { mutate, isLoading, isSuccess } = useApplyForProjectById();

  useEffect(() => {
    return () => {
      setIsGlobalLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading && !isGlobalLoading) setIsGlobalLoading(true);
    if (!isLoading && isGlobalLoading) setIsGlobalLoading(false);
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess, refetch]);

  const handleSubmit = useCallback(() => {
    setIsSubmitModalOpen(false);
    mutate({ projectId, data: { memo, projectId } });
    // TODO onSuccess 시 지원 현황으로 페이지 이동. 작업 시 refetch(); useEffect 제거.
  }, [mutate, projectId, memo]);

  const email = myApplicationInfo?.email || user.email!;
  const job = myApplicationInfo?.job || user.job!;
  const skillNameList = myApplicationInfo?.skillNameList || user.skillNameList!;
  const yearOfJob = myApplicationInfo?.yearOfJob || user.yearOfJob!;

  return (
    <Wrapper>
      <Container>
        <Title>나의 지원 내용</Title>

        <ItemsWrapper>
          <Item>
            <ItemField>이메일</ItemField>
            <ItemValue>{email}</ItemValue>
          </Item>

          <Item>
            <ItemField>직무</ItemField>
            <ItemValue>{jobViewer(job)}</ItemValue>
          </Item>

          <Item>
            <ItemField>스킬</ItemField>
            <ItemValue>{skillNameList.join(", ")}</ItemValue>
          </Item>

          <Item>
            <ItemField>경력</ItemField>
            <ItemValue>{yearOfJob + "년"}</ItemValue>
          </Item>

          {myApplicationInfo && (
            <Item>
              <ItemField>남긴 메모</ItemField>
              <ItemValue>{myApplicationInfo.memo}</ItemValue>
            </Item>
          )}

          {!isFetching && !myApplicationInfo && (
            <Item>
              <ItemField required={true}>남길 메모</ItemField>

              <StyledTextArea
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                placeholder="메모를 남겨주세요."
                autoSize={{ minRows: 5, maxRows: 5 }}
              />
            </Item>
          )}
        </ItemsWrapper>
      </Container>

      <ButtonWrapper>
        <Button
          type="default"
          htmlType="button"
          onClick={() => setIsHandleBackModalOpen(true)}
          style={{ height: "44px", width: "110px" }}
        >
          이전
        </Button>
        {!isFetching && !myApplicationInfo && (
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              if (!memo) message.error("메모를 남겨주세요.");
              else setIsSubmitModalOpen(true);
            }}
            style={{ height: "44px", width: "110px" }}
          >
            지원
          </Button>
        )}
      </ButtonWrapper>

      <Modal
        open={isSubmitModalOpen}
        onOk={handleSubmit}
        okText="네"
        onCancel={() => setIsSubmitModalOpen(false)}
        cancelText="취소"
        width={400}
      >
        <ModalContent>프로젝트에 지원하시겠습니까?</ModalContent>
      </Modal>
    </Wrapper>
  );
}

export default MyApplicationInfo;
