import { SelectProps } from "antd";
import {
  Job,
  MeetingChannel,
  ProjectPlatform,
  ProjectWorkerProgressStatus,
} from "../types";

export const jobOptions: SelectProps["options"] = [
  { label: "백엔드", value: Job.BACKEND_DEVELOPER },
  { label: "프론트엔드", value: Job.FRONTEND_DEVELOPER },
  { label: "기획", value: Job.PLANNER },
  { label: "디자인", value: Job.DESIGNER },
  { label: "PM", value: Job.PROJECT_MANAGER },
];

export const platformOptions: SelectProps["options"] = [
  { label: "웹", value: ProjectPlatform.WEB },
  { label: "안드로이드", value: ProjectPlatform.ANDROID },
  { label: "iOS", value: ProjectPlatform.IOS },
  { label: "기타", value: ProjectPlatform.ETC },
];

export const meetingChannelOptions: SelectProps["options"] = [
  { label: "오프라인", value: MeetingChannel.OFFLINE },
  { label: "온라인", value: MeetingChannel.ONLINE },
  { label: "오프라인&온라인 병행", value: MeetingChannel.OFFLINE_ONLINE },
  { label: "미정", value: MeetingChannel.UNDECIDED },
];

export const projectWorkerProgressStatusOptions: SelectProps["options"] = [
  { label: "시작 전", value: ProjectWorkerProgressStatus.BEFORE_START },
  { label: "진행 중", value: ProjectWorkerProgressStatus.IN_PROGRESS },
  {
    label: "완료",
    value: ProjectWorkerProgressStatus.COMPLETE,
  },
];
