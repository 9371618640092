export const pageRoute = {
  HOME: "/",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  OAUTH_CALLBACK: "/oauth-callback",
  MYPAGE: "/mypage",
  PROJECT_LIST: "/projects",
  PROJECT_DETAIL: "/projects/:id",
  PROJECT_REGISTER: "/projects/new",
  PROJECT_EDIT: "/projects/:id/edit",
  GUIDE: "/guide",
  MY_PROJECT: "/my-project",
  MY_PROJECT_RECRUITING_PROJECT_LIST: "/my-project/recruiting-projects",
  MY_PROJECT_APPLIED_PROJECT_LIST: "/my-project/applied-projects",
  MY_PROJECT_IN_PROGRESS_PROJECT_LIST: "/my-project/in-progress-projects",
  MY_PROJECT_IN_PROGRESS_PROJECT_DETAIL: "/my-project/in-progress-projects/:id",
  MY_PROJECT_COMPLETE_PROJECT_LIST: "/my-project/complete-projects",
  MY_PROJECT_COMPLETE_PROJECT_DETAIL: "/my-project/complete-projects/:id",
};
