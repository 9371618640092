import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorage } from "../helpers/storage.helper";
import { localStorageKey } from "../constants/localStorageKey.constant";
import { pageRoute } from "../constants/pageRoute.constant";

function GuestRoutes() {
  const isAuthenticated = getLocalStorage(localStorageKey.BEARER_TOKEN);

  return !isAuthenticated ? <Outlet /> : <Navigate to={pageRoute.HOME} />;
}

export default GuestRoutes;
