import { useRecoilValue } from "recoil";
import { isGlobalLoadingState } from "../../atoms/loadingState";
import { Spin } from "antd";

function Loading() {
  const isGlobalLoading = useRecoilValue(isGlobalLoadingState);

  return isGlobalLoading ? (
    <Spin tip="Loading..." size="large" fullscreen />
  ) : null;
}

export default Loading;
