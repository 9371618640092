import { useSetRecoilState } from "recoil";
import { isGlobalLoadingState } from "../../../atoms/loadingState";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageKey } from "../../../constants/localStorageKey.constant";
import { pageRoute } from "../../../constants/pageRoute.constant";
import { useOauthSignIn } from "../../../hooks/queries/useAccountQuery";
import { commonConstants } from "../../../constants/common.constant";
import { Provider } from "../../../types";
import { getLocalStorage } from "../../../helpers/storage.helper";
import useQueryParams from "../../../hooks/useQueryParams";

function OauthCallback() {
  const setIsGlobalLoading = useSetRecoilState(isGlobalLoadingState);
  const oauthSignInMutation = useOauthSignIn();
  const { queryParams } = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    const { code } = queryParams;
    const provider = getLocalStorage<Provider>(localStorageKey.OAUTH_PROVIDER);
    if (!code || !provider) return navigate(pageRoute.SIGN_IN);

    const data =
      process.env.NODE_ENV === commonConstants.nodeEnvs.PRODUCTION
        ? { code, provider }
        : { code, provider, isNodeEnvLocal: true };
    oauthSignInMutation.mutate(data);

    setIsGlobalLoading(true);

    return () => {
      setIsGlobalLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  return <></>;
}

export default OauthCallback;
