import { Job } from "./account.type";
import { ISimpleMedia } from "./common.type";

export interface IProjectList {
  rows: {
    id: number;
    createdAt: string;
    projectName: string;
    desiredDeadlineAt: string;
    isProjectRegistrant: boolean;
    projectPlatformListView: string;
    projectTasksJobView: string;
    meetingChannelView: string;
    isApplied: boolean;
  }[];
  count: number;
}

export const defaultProjectList: IProjectList = {
  rows: [
    {
      id: 0,
      createdAt: "-",
      projectName: "-",
      desiredDeadlineAt: "-",
      isProjectRegistrant: false,
      projectPlatformListView: "-",
      projectTasksJobView: "-",
      meetingChannelView: "-",
      isApplied: false,
    },
  ],
  count: 0,
};

export enum MeetingChannel {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  OFFLINE_ONLINE = "OFFLINE_ONLINE",
  UNDECIDED = "UNDECIDED",
}

export const meetingChannelViewer = (
  meetingChannel?: MeetingChannel
): string => {
  switch (meetingChannel) {
    case MeetingChannel.OFFLINE:
      return "오프라인";
    case MeetingChannel.ONLINE:
      return "온라인";
    case MeetingChannel.OFFLINE_ONLINE:
      return "오프라인&온라인 병행";
    case MeetingChannel.UNDECIDED:
      return "미정";
    default:
      return "-";
  }
};

export enum ProjectPlatform {
  WEB = "WEB",
  ANDROID = "ANDROID",
  IOS = "IOS",
  ETC = "ETC",
}

export const projectPlatformListViewer = (
  projectPlatformList: ProjectPlatform[]
): string => {
  return (
    projectPlatformList
      .map((projectPlatform) => {
        switch (projectPlatform) {
          case ProjectPlatform.WEB:
            return "웹";
          case ProjectPlatform.ANDROID:
            return "안드로이드";
          case ProjectPlatform.IOS:
            return "iOS";
          case ProjectPlatform.ETC:
            return "기타";
          default:
            return projectPlatform;
        }
      })
      .join(", ") || "-"
  );
};

export interface ISimpleProjectTask {
  job: Job;
  isProjectRegistrantTask: boolean;
  recruitsNumber: number;
  jobDescription?: string;
}

export interface IProjectDetail {
  id: number;
  createdAt: string;
  projectName: string;
  projectPlatformList: ProjectPlatform[];
  meetingChannel: MeetingChannel;
  region1DepthCodeItemName?: string;
  region2DepthCodeItemName?: string;
  meetingSchedule: string;
  desiredDeadlineAt: string;
  description: string;
  isProjectRegistrant: boolean;
  simpleProjectTasks: ISimpleProjectTask[];
  preferredMeetingRegionView?: string;
}

export const defaultProjectDetail: IProjectDetail = {
  id: 0,
  createdAt: "-",
  projectName: "-",
  projectPlatformList: [],
  meetingChannel: MeetingChannel.UNDECIDED,
  region1DepthCodeItemName: undefined,
  region2DepthCodeItemName: undefined,
  meetingSchedule: "-",
  desiredDeadlineAt: "-",
  description: "-",
  isProjectRegistrant: false,
  simpleProjectTasks: [],
  preferredMeetingRegionView: "-",
};

export interface IMyApplicationInfo {
  id: number;
  memo: string;
  email: string;
  job: Job;
  yearOfJob: number;
  skillNameList: string[];
}

export enum ProjectWorkerStatus {
  APPLIED = "APPLIED", // 지원
  REJECTED = "REJECTED", // 거절
  ACCEPTED = "ACCEPTED", // 승낙
}

export interface IApplicantList {
  rows: {
    id: number;
    projectWorkerStatus: ProjectWorkerStatus;
    email: string;
    job: Job;
    skillNameList: string[];
    yearOfJob: number;
    memo: string;
  }[];
  count: number;
}

export interface IMyRecruitingProjectList {
  rows: {
    id: number;
    createdAt: string;
    projectName: string;
    desiredDeadlineAt: string;
    projectPlatformListView: string;
    projectTasksJobView: string;
    meetingChannelView: string;
    newApplicantCount: number;
  }[];
  count: number;
}

export interface IMyAppliedProjectList {
  rows: {
    id: number;
    createdAt: string;
    projectName: string;
    desiredDeadlineAt: string;
    projectPlatformListView: string;
    projectTasksJobView: string;
    meetingChannelView: string;
    projectWorkerStatus: ProjectWorkerStatus;
  }[];
  count: number;
}

export interface IMyInProgressProjectList {
  rows: {
    id: number;
    createdAt: string;
    projectName: string;
    desiredDeadlineAt: string;
    projectWorkerDeadlineAt?: string;
    desiredDeadlineDday: string;
    totalCompletionRate: number;
  }[];
  count: number;
}

export enum ProjectWorkerProgressStatus {
  BEFORE_START = "BEFORE_START", // 시작 전
  IN_PROGRESS = "IN_PROGRESS", // 진행 중
  COMPLETE = "COMPLETE", // 완료
}

export interface ISimpleProjectWorker {
  id: number;
  projectWorkerDeadlineAt?: string;
  projectWorkerProgressStatus: ProjectWorkerProgressStatus;
  name: string;
  job: Job;
  score: number;
}

export interface IMyInProgressProjectDetail {
  id: number;
  createdAt: string;
  projectName: string;
  desiredDeadlineAt: string;
  desiredDeadlineDday: string;
  totalCompletionRate: number;
  projectIntroductionUrlList: string[];
  projectCompletedAt?: string;
  simpleProjectWorkers: ISimpleProjectWorker[];
  introductionSimpleMedias?: ISimpleMedia[];
}

export const defaultMyInProgressProjectDetail: IMyInProgressProjectDetail = {
  id: 0,
  createdAt: "-",
  projectName: "-",
  desiredDeadlineAt: "-",
  desiredDeadlineDday: "-",
  totalCompletionRate: 0,
  projectIntroductionUrlList: [],
  projectCompletedAt: undefined,
  simpleProjectWorkers: [],
  introductionSimpleMedias: undefined,
};

export interface IMyCompleteProjectList {
  rows: {
    id: number;
    createdAt: string;
    projectName: string;
    desiredDeadlineAt: string;
    totalCompletionRate: number;
    projectCompletedAt: string;
  }[];
  count: number;
}

export const projectWorkerProgressStatusViewer = (
  projectWorkerProgressStatus?: ProjectWorkerProgressStatus
): string => {
  switch (projectWorkerProgressStatus) {
    case ProjectWorkerProgressStatus.BEFORE_START:
      return "시작 전";
    case ProjectWorkerProgressStatus.IN_PROGRESS:
      return "진행 중";
    case ProjectWorkerProgressStatus.COMPLETE:
      return "완료";
    default:
      return "-";
  }
};

export interface IMyCompleteProjectDetail {
  id: number;
  createdAt: string;
  projectName: string;
  desiredDeadlineAt: string;
  totalCompletionRate: number;
  projectIntroductionUrlList: string[];
  projectCompletedAt: string;
  simpleProjectWorkers: ISimpleProjectWorker[];
  projectPlatformList: ProjectPlatform[];
  meetingChannel: MeetingChannel;
  region1DepthCodeItemName?: string;
  region2DepthCodeItemName?: string;
  meetingSchedule?: string;
  preferredMeetingRegionView?: string;
  introductionSimpleMedias?: ISimpleMedia[];
}

export const defaultMyCompleteProjectDetail: IMyCompleteProjectDetail = {
  id: 0,
  createdAt: "-",
  projectName: "-",
  desiredDeadlineAt: "-",
  totalCompletionRate: 0,
  projectIntroductionUrlList: [],
  projectCompletedAt: "-",
  simpleProjectWorkers: [],
  projectPlatformList: [],
  meetingChannel: MeetingChannel.UNDECIDED,
  region1DepthCodeItemName: undefined,
  region2DepthCodeItemName: undefined,
  meetingSchedule: undefined,
  preferredMeetingRegionView: undefined,
  introductionSimpleMedias: undefined,
};
