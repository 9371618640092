import { useMutation, useQuery } from "react-query";
import { httpGet, httpPost, httpPut } from "../../helpers/httpRequest.helper";
import { HttpStatusCode } from "axios";
import { localStorageKey } from "../../constants/localStorageKey.constant";
import { useNavigate } from "react-router-dom";
import { pageRoute } from "../../constants/pageRoute.constant";
import { useSetRecoilState } from "recoil";
import { signUpAuthTokenIdState, userState } from "../../atoms/accountState";
import { queryKey } from "../../constants/queryKey.constant";
import { ENV } from "../../env";
import {
  removeLocalStorage,
  setLocalStorage,
} from "../../helpers/storage.helper";
import {
  IUser,
  Job,
  Provider,
  IOauthSignUpToken,
  ISignUpAuthTokenId,
} from "../../types";

const { SUBTASK_SERVER_URL } = ENV;

interface IWorkerOauthSignIn {
  code: string;
  provider: Provider;
  isNodeEnvLocal?: boolean;
}

export const useOauthSignIn = () => {
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);

  return useMutation(
    async (data: IWorkerOauthSignIn) => {
      return httpPost(
        SUBTASK_SERVER_URL + "/v1/worker/sign-in/oauth",
        undefined,
        data
      );
    },
    {
      onSuccess: ({ status, data, headers }) => {
        removeLocalStorage(localStorageKey.OAUTH_PROVIDER);

        if (status === HttpStatusCode.Created) {
          const { authorization: bearerToken } = headers;
          setLocalStorage(localStorageKey.BEARER_TOKEN, bearerToken);

          const user: IUser = data.row;
          setUser(user);

          navigate(pageRoute.HOME);
        } else if (status === HttpStatusCode.Accepted) {
          const oauthSignUpToken: IOauthSignUpToken = data.row;
          setLocalStorage(
            localStorageKey.OAUTH_SIGN_UP_TOKEN,
            oauthSignUpToken
          );

          navigate(pageRoute.SIGN_UP);
        }
      },
      onError: (error) => {
        removeLocalStorage(localStorageKey.OAUTH_PROVIDER);
        navigate(pageRoute.SIGN_IN);
      },
    }
  );
};

export const useGetUser = ({ enabled = true }) => {
  const setUser = useSetRecoilState(userState);

  return useQuery(
    queryKey.GET_USER,
    async () => {
      return httpGet(SUBTASK_SERVER_URL + "/v1/workers/this");
    },
    {
      onSuccess: ({ data }) => {
        const user: IUser = data.row;
        setUser(user);
      },
      enabled,
    }
  );
};

export interface IUpdateUser {
  isMarketingEmailReceive?: boolean;
  name: string;
  job?: Job;
  yearOfJob?: number;
  introduction?: string;
  skillNameList: string[];
}

export const useUpdateUser = () => {
  const setUser = useSetRecoilState(userState);

  return useMutation(
    async (data: IUpdateUser) => {
      return httpPut(SUBTASK_SERVER_URL + "/v1/workers/this", data);
    },
    {
      onSuccess: ({ data }) => {
        const user: IUser = data.row;
        setUser(user);
      },
    }
  );
};

interface ISendSignUpVerifyStringEmail {
  email: string;
}

export const useSendSignUpVerifyStringEmail = () => {
  const setAuthTokenId = useSetRecoilState(signUpAuthTokenIdState);

  return useMutation(
    async (data: ISendSignUpVerifyStringEmail) => {
      return httpPost(
        SUBTASK_SERVER_URL + "/v1/worker/send-sign-up-verify-string-email",
        undefined,
        data
      );
    },
    {
      onSuccess: ({ data }) => {
        const authTokenId: ISignUpAuthTokenId = data.row;
        setAuthTokenId(authTokenId);
      },
    }
  );
};

interface IWorkerOauthSignUp {
  authToken: string;
  isMarketingEmailReceive: boolean;
  email?: string;
  name: string;
  authTokenId?: string;
  verifyString?: string;
}

export const useOauthSignUp = () => {
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);

  return useMutation(
    async (data: IWorkerOauthSignUp) => {
      return httpPost(
        SUBTASK_SERVER_URL + "/v1/worker/sign-up/oauth",
        undefined,
        data
      );
    },
    {
      onSuccess: ({ data, headers }) => {
        removeLocalStorage(localStorageKey.OAUTH_SIGN_UP_TOKEN);

        const { authorization: bearerToken } = headers;
        setLocalStorage(localStorageKey.BEARER_TOKEN, bearerToken);

        const user: IUser = data.row;
        setUser(user);

        navigate(pageRoute.HOME);
      },
    }
  );
};

export const useSignOut = () => {
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);

  return useMutation(
    async () => {
      return httpPost(SUBTASK_SERVER_URL + "/v1/worker/sign-out");
    },
    {
      onSuccess: () => {
        removeLocalStorage(localStorageKey.BEARER_TOKEN);

        setUser(null);

        navigate(pageRoute.SIGN_IN);
      },
    }
  );
};
